/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
  AsyncAction, CreateListOperation, DeleteListItemOperation,
  SyncController, UpdateListItemOperation,
  UpsertListItemOperation,
  failed, loading, success
} from "common/utils";
import { MerchantAccount } from "../../merchant.entities";
import { SyncMerchantAccountsState } from '../../merchant.store';


export function SyncMerchantAccounts(ctrl: SyncController): AsyncAction {
  return async (dispatch, _, api) => {
    dispatch(SyncMerchantAccountsState(loading()));
    try {
      const io = api.sync<MerchantAccount>("/pricing/merchants", {
        onConnected: (socket) => socket.emit("sync"),
        onEvent: {
          data: (data) => {
            dispatch(SyncMerchantAccountsState(success(new CreateListOperation(data))));
          },
          create: (item) => {
            dispatch(SyncMerchantAccountsState(success(new UpsertListItemOperation(item, test))));
          },
          update: (item) => {
            dispatch(SyncMerchantAccountsState(success(new UpdateListItemOperation(item, test))));
          },
          delete: (item) => {
            dispatch(SyncMerchantAccountsState(success(new DeleteListItemOperation(item, test))));
          }
        },
        onError(error) {
          dispatch(SyncMerchantAccountsState(failed(error)));
        },
      });
      ctrl.attach({
        onCancel: () => io.disconnect(),
      })
    } catch (error) {
      dispatch(SyncMerchantAccountsState(failed(error)));
    }
  };
}

function test(a: MerchantAccount, b: MerchantAccount) {
  return a.id === b.id;
}