/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { useSyncBranches } from "branch/branch.hooks";
import { useSyncCatalogs } from "catalog/catalog.hooks";
import { useSyncCategories } from "category/category.hooks";
import { isSuccessful } from "common/utils";
import { useSyncEmployees } from "employee/employee.hooks";
import { useSyncMerchantAccounts } from "merchant/merchant.hooks";
import { useSyncProducts } from "product/product.hooks";
import { useGetSubscription } from "subscription/subscription.hooks";
import styles from "./styles.module.scss";


export default function ChecklistForm({
  className, ...rest
}: ChecklistFormProps) {
  const classes = useMemo(() => [styles.checklist, className].join(" "), [className]);

  const { syncProductsState } = useSyncProducts();
  const { syncCategoriesState } = useSyncCategories();
  const { syncCatalogsState } = useSyncCatalogs();
  const { syncBranchesState } = useSyncBranches();
  const { syncEmployeesState } = useSyncEmployees();
  const { syncMerchantAccountsState } = useSyncMerchantAccounts();
  const { getCurrentSubscriptionState } = useGetSubscription();

  return (
    <div className={classes} {...rest}>
      <ul>
        <li>Create Business Profile</li>
        <ul>
          <li>
            <a href="/business" target="_blank">Create your business profile</a>
            <span>✅</span>
          </li>
        </ul>
        <li>Add Menu Items</li>
        <ul>
          <li>
            <a href="/products/new" target="_blank">Add your menu products</a>
            {isSuccessful(syncProductsState) && (
              <span>{syncProductsState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Add Menu Categories</li>
        <ul>
          <li>
            <a href="/categories/new" target="_blank">Organize your menu with categories</a>
            {isSuccessful(syncCategoriesState) && (
              <span>{syncCategoriesState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Set Up the Menu</li>
        <ul>
          <li>
            <a href="/catalogs/new" target="_blank">Set up your complete catalog</a>
            {isSuccessful(syncCatalogsState) && (
              <span>{syncCatalogsState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Set Up Your Merchant Account</li>
        <ul>
          <li>
            <a href="/merchants/stripe/new" target="_blank">Enable Online Payments</a>
            {isSuccessful(syncMerchantAccountsState) && (
              <span>{syncMerchantAccountsState.value!.find(m => m.type === 'stripe' && m.status === 'active') != null ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Setup Free Trial (Subscription)</li>
        <ul>
          <li>
            <a href="/subscription" target="_blank">Start your free trial</a>
            {getCurrentSubscriptionState !== undefined && (
              <span>{getCurrentSubscriptionState.value != null ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Create and Configure a Branch</li>
        <ul>
          <li>
            <a href="/branches/new" target="_blank">Add branches and configure their opening hours</a>
            {isSuccessful(syncBranchesState) && (
              <span>{syncBranchesState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Add and Invite Staff (Optional)</li>
        <ul>
          <li>
            <a href="/employees/new" target="_blank">Invite your staff</a>
            {isSuccessful(syncEmployeesState) && (
              <span>{syncEmployeesState.value!.length > 0 ? "✅" : "❌"}</span>
            )}
          </li>
        </ul>
        <li>Download and Start Using the DishDash Business App</li>
        <ul>
          <li>
            <a href="/apps" target="_blank">Download the app to receive and manage orders</a>
          </li>
        </ul>
        <li>Digital Marketing Assets</li>
        <ul>
          <li>
            <a href="/digital-marketing-assets" target="_blank">Use your digital marketing assets on Social Media and other marketing campaigns</a>
          </li>
        </ul>
        <li>Contact Sales Support</li>
        <ul>
          <li>
            <a href="mailto:support@dishdash.io" target="_blank">Reach out to Sales Support if you need any assistance</a>
          </li>
        </ul>
      </ul>

    </div>
  );
}

export type ChecklistFormProps = {
  className?: string;
};