/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Product } from "product/product.entities";
import { AsyncAction, failed, loading, success } from "common/utils";
import { DeleteProductState } from "../product.store";


export function DeleteProduct(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteProductState(loading()));
    try {
      const { value } = await api.delete(`/catalog/products/${id}`);
      dispatch(DeleteProductState(success(value)));
    } catch (error) {
      dispatch(DeleteProductState(failed(error)));
    }
  };
}
