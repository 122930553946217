/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _, { identity } from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStripe } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError,
  Button, Callout, Card, CardContent, CardHeader,
  Grid, PageContent, PageHeader, PageNotFound, Toast
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors,
} from 'common/utils';
import { CreateMerchantAccount } from 'merchant/common/actions/create-merchant-account.action';
import { DeleteMerchantAccount } from 'merchant/common/actions/delete-merchant-account.action';
import { UpdateMerchantAccount } from 'merchant/common/actions/update-merchant-account.action';
import MerchantAccountForm from 'merchant/common/components/MerchantAccountForm';
import { StripeMerchantAccount } from 'merchant/merchant.entities';
import { useSyncMerchantAccounts } from 'merchant/merchant.hooks';
import { useIdentity } from 'auth/auth.hooks';


export default function StipeMerchantAccountPage() {
  const { id } = useParams();
  const initial = useInitial();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { t } = useTranslation();
  const identity = useIdentity();

  // store
  const { syncMerchantAccountsState, merchant } = useSyncMerchantAccounts<StripeMerchantAccount>({
    find: merchant => merchant.id === id,
  });
  const [saveState, dispatch] = useAppState((store) => store.merchant.save);
  const [deleteState] = useAppState((store) => store.merchant.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<StripeMerchantAccount>(merchant, {
      type: 'stripe',
      name: 'Stripe',
      displayName: identity?.business?.name,
      uponArrivalPayments: true,
      atCounterPayments: false,
      cardPayments: true,
      applePay: true,
      googlePay: true,
      afterOnboardingRedirectUrl: window.location.href.replace('/new', '/${id}'),
    }, [merchant]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (id === "new") {
          const acc = saveState.value as StripeMerchantAccount;
          if (acc.status === 'onboarding')
            window.location.replace(acc.stripeOnboardingLink!);
          else
            navigate(pathname.replace("/new", `/${saveState.value!.id}`), { replace: true });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: "Merchant Account Deleted" });
        navigate('/merchants');
      } else if (deleteState.error) {
        if (deleteState.error.code === 'in-use') {
          confirmDeletion(false);
          Toast.showDanger({
            message: `This Merchant Account is being used by "${deleteState.error['branch']['name']}" branch, remove from the branch and try again`,
          })
        } else {
          Toast.showAsyncError(t, deleteState.error);
        }
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        icon={<BsStripe size={20} style={{ marginRight: 5 }} />}
        title={
          (merchant?.name || "New Stripe Merchant Account") +
          (merchant?.status === 'onboarding' ? ' (onboarding)' : '')
        }
        backButtonPath={state?.backpath || "/merchants"}>
        <Button
          text="Continue"
          intent="primary"
          onClick={() => dispatch(CreateMerchantAccount(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Complete Onboarding"
          intent="primary"
          onClick={() => window.location.replace(merchant!.stripeOnboardingLink!)}
          hidden={merchant?.status !== 'onboarding'}
          loading={isLoading(saveState)}
        />
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(UpdateMerchantAccount(merchant!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={merchant == null}
                disabled={merchant == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        {id === 'new' && (
          <Callout intent='primary' style={{ marginBottom: 30 }}>
            We partner with <a href='https://stripe.com' target='_blan'>Stripe</a> to process all payments. Stripe is a global payment gateway based in the USA. The onboarding process is managed by Stripe. Your payment details are stored and managed by Stripe, we do not store any of your payment details.
          </Callout>
        )}
        <AsyncStateBuilder state={syncMerchantAccountsState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!merchant && id !== "new")
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={["General"]} />
                  <CardContent>
                    <MerchantAccountForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState) || merchant?.status === 'onboarding'}
                      onChange={onChange}
                      hiddenFields={id === 'new' ? ['currency'] : undefined}
                      readOnlyFields={id !== 'new' ? ['currency'] : []}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteMerchantAccount(id!))}>
          <p>Delete this Merchant Account?</p>
        </Alert>
      </PageContent>
    </>
  );
}
