/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { ListOperation } from "common/utils";
import { AsyncState, isSuccessful } from "common/utils/async-state";
import { Employee } from "employee/employee.entities";


export const EmployeeStore = createSlice({
  name: "employee",
  initialState: {
    sync: {} as AsyncState<Employee[]>,
    save: {} as AsyncState<Employee>,
    delete: {} as AsyncState<Employee>,
  },
  reducers: {
    SyncEmployeesState: (
      state,
      action: { payload: AsyncState<ListOperation<Employee>> },
    ) => {
      state.sync = {
        ...state.sync,
        ...action.payload,
        value: action.payload.value?.apply(state.sync.value) || state.sync.value,
      }
    },
    SaveEmployeeState: (
      state,
      action: { payload: AsyncState<Employee> },
    ) => {
      state.save = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.id);
        const newSyncValue = state.sync.value!.slice();
        if (idx === -1) {
          newSyncValue.push(action.payload.value!);
        } else {
          newSyncValue[idx] = action.payload.value!;
        }
        state.sync.value = newSyncValue;
      }
    },
    DeleteEmployeeState: (
      state,
      action: { payload: AsyncState<Employee> },
    ) => {
      state.delete = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.id);
        if (idx !== -1) {
          const newSyncValue = state.sync.value!.slice();
          newSyncValue.splice(idx, 1);
          state.sync.value = newSyncValue;
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  SyncEmployeesState,
  SaveEmployeeState,
  DeleteEmployeeState,
} = EmployeeStore.actions;

export const { reducer: employeeReducer, getInitialState } = EmployeeStore;

export type EmployeeState = ReturnType<typeof EmployeeStore.getInitialState>
