/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useMemo } from "react";

import { Button, Row } from 'common/components';
import { Form, FormField, NumberRangeField, TextAreaField, TextInputField } from "common/components/form";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import OptionForm from "product/components/OptionForm";
import { ProductOptionGroup } from "product/product.entities";

import styles from "./styles.module.scss";


export default function OptionGroupForm<T extends ProductOptionGroup>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: OptionGroupFormProps<T>) {
  const classes = useMemo(() => [styles.optionGroupForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form className={classes} {...rest}>
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextAreaField
        name={fieldName("description")}
        label="Description"
        value={value?.description}
        error={errors?.description}
        readOnly={readOnly}
        hidden={isHidden("description")}
        onChange={onChange} />
      <FormField
        label={["Options"]}
        error={errors?.options} >
        {(value?.options || []).map((option, idx) => (
          <div className={styles.optionRow}>
            <OptionForm
              key={`option_${idx}`}
              name={fieldName(`options.${idx}`)}
              value={option}
              errors={_.get(errors?.options, idx)}
              readOnly={readOnly}
              hidden={isHidden(`options.${idx}`)}
              onChange={onChange}
            />
            <Button
              minimal
              icon="trash"
              onClick={() => {
                const newOptions = value?.options.filter((_opt, oi) => oi !== idx);
                onChange({
                  target: {
                    name: fieldName("options"),
                    value: newOptions,
                  }
                })
              }}
            />
          </div>
        ))}
      </FormField>
      <NumberRangeField
        label="Min/Max Selected"
        startName={fieldName('minSelected')}
        endName={fieldName('maxSelected')}
        startValue={value?.minSelected || 0}
        endValue={value?.maxSelected || 0}
        max={value?.options?.length || 0}
        error={errors?.minSelected || errors?.maxSelected}
        readOnly={readOnly}
        hidden={isHidden("minSelected") || isHidden('maxSelected')}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type OptionGroupFormProps<T extends ProductOptionGroup> = EntityFormProps<T> & {
};