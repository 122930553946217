/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Category } from "category/category.entities";
import { AsyncAction, failed, loading, success } from "common/utils";
import { SaveCategoryState } from "../category.store";


export function UpdateCategory(id: string, changes: Partial<Category>): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(SaveCategoryState(loading()));
    try {
      const { value } = await api.put(`/catalog/categories/${id}`, { data: changes })
      dispatch(SaveCategoryState(success(value)));
    } catch (error) {
      dispatch(SaveCategoryState(failed(error)));
    }
  };
}
