/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { SendOtpState } from "auth/auth.store";
import { ExtPhone } from "common/common.entities";
import { AsyncAction, failed, loading, success } from "common/utils";


export function SendOtpAction(phone?: ExtPhone, email?: string): AsyncAction {
  return async (dispatch, getState, api) => {
    dispatch(SendOtpState(loading()));
    try {
      const { value } = await api.post("/auth/otp", {
        data: {
          phone,
          email,
          apiKey: process.env.REACT_APP_API_KEY,
        },
      });
      dispatch(SendOtpState(success(value.token)));
    } catch (error) {
      dispatch(SendOtpState(failed(error)));
    }
  };
}