/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from "react";

import { ExPhoneField, TextInputField } from 'common/components/form';
import DropdownField, { DropdownFieldProps } from 'common/components/form/Dropdown';
import { ExPhoneFieldProps } from 'common/components/form/ExPhone';
import { FormFieldProps } from 'common/components/form/Field';
import Form, { FormProps } from 'common/components/form/Form';
import TextAreaField, { FormTextAreaProps } from 'common/components/form/TextArea';
import { FormTextInputProps } from 'common/components/form/TextInput';
import { OnChangeEventHandler } from "common/utils";
import styles from "./styles.module.sass";


export default function EntityForm<T>({
  className, children, name, value, errors, fields, fieldsOrder,
  visibleFields, hiddenFields, readOnlyFields, disabledFields,
  readOnly, disabled, onChange, ...rest }: EntityFormProps<T>) {
  const classes = useMemo(() => {
    const cls = [styles.entityForm, className];
    if (className) cls.push(className)
    return cls.join(" ").trim();
  }, [className]);

  const fieldElements = useMemo(() => {
    const fieldNames = fieldsOrder || fields?.map(f => f.name) || [];
    return fieldNames.map((fieldName, index) => {
      const field = fields!.find(field => field.name === fieldName)!;
      const fullName = _.isEmpty(name) ? field.name : (name + '.' + field.name) as any;
      const props = {
        key: `field_${index}`,
        name: fullName,
        value: _.get(value, fullName),
        error: _.get(errors, fullName),
        hidden: visibleFields?.includes(fullName) === false || hiddenFields?.includes(fullName) === true,
        readOnly: readOnly || readOnlyFields?.includes(fullName) === true,
        disabled: disabled || disabledFields?.includes(fullName) === true,
        onChange: onChange,
        ..._.omit(field, 'type', 'name')
      }
      switch (field.type) {
        case 'input':
          return <TextInputField  {...props as any} />
        case 'textarea':
          return <TextAreaField {...props as any} />
        case 'dropdown':
          return <DropdownField {...props as any} />
        case 'ex-phone':
          return <ExPhoneField {...props as any} />
        case 'custom':
          return field.render(props);
      }
    });
  }, [
    name, fields, value, errors, readOnly, disabled, onChange,
    visibleFields, hiddenFields, readOnlyFields, disabledFields,
    fieldsOrder,
  ]);

  return (
    <Form className={classes} {...rest}>
      {fieldElements}
      {children}
    </Form>
  );
}

type Join<K, P> = K extends string | number ?
  P extends string | number ?
  `${K}${"" extends P ? "" : "."}${P}`
  : never : never;
type Prev = [never, 0, 1, 2, 3, 4, ...0[]];
type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
  { [K in keyof T]-?: K extends string | number ?
    `${K}` | Join<K, Paths<T[K], Prev[D]>>
    : never
  }[keyof T] : ""

// type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type BaseEntityFieldProps = FormFieldProps & {
  name: string;
}

export type TypedEntityFieldProps =
  | { type: 'input' } & FormTextInputProps
  | { type: 'textarea' } & FormTextAreaProps
  | { type: 'dropdown' } & DropdownFieldProps<any>
  | { type: 'ex-phone' } & ExPhoneFieldProps
  | { type: 'custom', render: (props: any) => JSX.Element };

export type EntityFormProps<T = any> = FormProps & {
  name?: string,
  value?: T,
  fields?: Array<BaseEntityFieldProps & TypedEntityFieldProps>;
  errors?: { [K in keyof T]: any },
  readOnly?: boolean,
  disabled?: boolean,
  onChange: OnChangeEventHandler<any>,
  visibleFields?: Array<Paths<T>>,
  hiddenFields?: Array<Paths<T>>,
  readOnlyFields?: Array<Paths<T>>,
  disabledFields?: Array<Paths<T>>,
  fieldsOrder?: Array<Paths<T>>,
}