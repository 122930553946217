/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { SyncTables } from "table/actions/sync-tables.action";
import { Table } from "table/table.entities";
import { isEmpty, SyncController, useAppState } from "common/utils";


export function useSyncTables<T extends Table = Table>(opts: {
  auto?: boolean,
  find?: (value: Table, index: number, array: Table[]) => boolean,
  filter?: (value: Table, index: number, array: Table[]) => boolean,
} = {}) {
  const [syncTablesState, dispatch] = useAppState((state) => state.table.sync);

  const syncTables = useCallback(() => {
    const unsub = new SyncController();
    dispatch(SyncTables(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncTablesState))
      syncTables();
  }, [opts.auto, syncTablesState])

  const tables = useMemo(() =>
    ((opts.filter == null
      ? syncTablesState.value
      : syncTablesState.value?.filter(opts.filter)) || []) as T[],
    [syncTablesState, opts.filter]);

  const table = useMemo(() =>
    opts.find == null
      ? null
      : syncTablesState.value?.find(opts.find) as T,
    [syncTablesState, opts.find]);

  return { syncTablesState, syncTables, tables, table };
}