/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { SearchAddressState } from "../geo.store";


export function SearchAddress(query: string, componentId: string): AsyncAction {
  return async (dispatch, _, api) => {
    dispatch(SearchAddressState(loading()));
    try {
      const { value } = await api.get("/geo/address/search", {
        params: { query }
      });
      dispatch(SearchAddressState(success({
        meta: { query, componentId },
        suggestions: value,
      })));
    } catch (error) {
      dispatch(SearchAddressState(failed(error)));
    }
  };
}
