/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { ListOperation } from "common/utils";
import { AsyncState, isSuccessful } from "common/utils/async-state";
import { Product } from "product/product.entities";


export const ProductStore = createSlice({
  name: "product",
  initialState: {
    sync: {} as AsyncState<Product[]>,
    save: {} as AsyncState<{ status: string, entity: Product }>,
    delete: {} as AsyncState<Product>,
  },
  reducers: {
    SyncProductsState: (
      state,
      action: { payload: AsyncState<ListOperation<Product>> },
    ) => {
      state.sync = {
        ...state.sync,
        ...action.payload,
        value: action.payload.value?.apply(state.sync.value) || state.sync.value,
      }
    },
    SaveProductState: (
      state,
      action: { payload: AsyncState<{ status: string, entity: Product }> },
    ) => {
      state.save = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.entity.id);
        const newSyncValue = state.sync.value!.slice();
        if (idx === -1) {
          newSyncValue.push(action.payload.value!.entity);
        } else {
          newSyncValue[idx] = action.payload.value!.entity;
        }
        state.sync.value = newSyncValue;
      }
    },
    DeleteProductState: (
      state,
      action: { payload: AsyncState<Product> },
    ) => {
      state.delete = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.id);
        if (idx !== -1) {
          const newSyncValue = state.sync.value!.slice();
          newSyncValue.splice(idx, 1);
          state.sync.value = newSyncValue;
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  SyncProductsState,
  SaveProductState,
  DeleteProductState,
} = ProductStore.actions;

export const { reducer: productReducer, getInitialState } = ProductStore;

export type ProductState = ReturnType<typeof ProductStore.getInitialState>
