/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, UpdateListItemOperation, failed, loading, success } from "common/utils";
import { Order } from "order/order.entities";
import { ConfirmOrderState, LoadOrderState, SyncOrdersState } from "../order.store";


export function ConfirmOrder(id: string, etr: number): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(ConfirmOrderState(loading()));
    try {
      const { value } = await api.patch(`/orders/${id}/confirm`, {
        params: {
          etr,
        }
      });
      dispatch(ConfirmOrderState(success(value)));
      dispatch(SyncOrdersState(success(new UpdateListItemOperation(value, test))));
      dispatch(LoadOrderState({ id, state: success(value) }));
    } catch (error) {
      dispatch(ConfirmOrderState(failed(error)));
    }
  };
}

function test(a: Order, b: Order) {
  return a.id === b.id;
}