/* 
 * Copyright (C) Deeprake.ru - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Menu, MenuItem } from "@blueprintjs/core";
import { Popover2Props } from "@blueprintjs/popover2";
import _ from 'lodash';

import { OnChangeEventHandler } from "common/utils";
import { Product } from "product/product.entities";


export default function OptionGroupMenu({
  product, optionGroupIndex, onChange,
}: NetworkHiddenLayersMenuProps) {
  const value = product.optionGroups![optionGroupIndex];

  return (
    <Menu>
      <MenuItem
        key="add"
        icon="add"
        text="Add Option"
        onClick={() => {
          const groups = product.optionGroups!.slice();
          const group = _.cloneDeep(groups[optionGroupIndex]);
          group.options.push({ id: null as any, name: '', price: 0 })
          groups[optionGroupIndex] = group;
          onChange({
            target: {
              name: 'optionGroups',
              value: groups,
            },
          })
        }} />
      <MenuItem
        key="add"
        icon="plus"
        text="Add Option Group Below"
        onClick={() => {
          const groups = product.optionGroups!.slice();
          groups.splice(optionGroupIndex + 1, 0, {
            id: null as any,
            options: [{
              id: null as any,
              name: '',
              price: 0,
            }],
            minSelected: 0,
            maxSelected: 1
          });
          onChange({
            target: {
              name: 'optionGroups',
              value: groups,
            },
          })
        }} />
      {optionGroupIndex >= 1 &&
        <MenuItem
          key="move-up"
          icon="arrow-up"
          text="Move Up"
          onClick={() => {
            const groups = product.optionGroups!.slice();
            const up = groups[optionGroupIndex - 1];
            groups[optionGroupIndex - 1] = value;
            groups[optionGroupIndex] = up;
            onChange({
              target: {
                name: 'optionGroups',
                value: groups,
              },
            })
          }} />
      }
      {optionGroupIndex < product.optionGroups!.length - 1 &&
        <MenuItem
          key="move-bottom"
          icon="arrow-down"
          text="Move Down"
          onClick={() => {
            const groups = product.optionGroups!.slice();
            const down = groups[optionGroupIndex + 1];
            groups[optionGroupIndex + 1] = value;
            groups[optionGroupIndex] = down;
            onChange({
              target: {
                name: 'optionGroups',
                value: groups,
              },
            })
          }} />
      }
      <MenuItem
        key="delete"
        icon="trash"
        text="Remove Option Group"
        intent='danger'
        onClick={() => {
          const groups = product.optionGroups!.slice();
          groups.splice(optionGroupIndex, 1);
          onChange({
            target: {
              name: 'optionGroups',
              value: groups.length === 0 ? null : groups,
            },
          })
        }} />
    </Menu>
  );
}

export type NetworkHiddenLayersMenuProps = Popover2Props & {
  product: Product;
  optionGroupIndex: number;
  onChange: OnChangeEventHandler<any>,
}