/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, SyncController, failed, loading, success } from "common/utils";
import { VisitorLocation } from "geo/geo.entities";
import { UpdateSyncVisitorLocationState } from '../geo.store';


export function SyncVisitorLocationAction(orderId: string, ctrl: SyncController): AsyncAction {
  return async (dispatch, _, api) => {
    dispatch(UpdateSyncVisitorLocationState({ id: orderId, state: loading() }));
    try {
      const io = api.sync<VisitorLocation>("/geo/visitor-location", {
        onConnected: (socket) => socket.emit("sync", orderId),
        onEvent: {
          data: (data) => {
            if (data.length)
              dispatch(UpdateSyncVisitorLocationState({ id: orderId, state: success(data[0]) }));
          },
          create: (item) => {
            dispatch(UpdateSyncVisitorLocationState({ id: orderId, state: success(item) }));
          },
          update: (item) => {
            dispatch(UpdateSyncVisitorLocationState({ id: orderId, state: success(item) }));
          },
          delete: (item) => {
          }
        },
        onError(error) {
          dispatch(UpdateSyncVisitorLocationState({ id: orderId, state: failed(error) }));
        },
      });
      ctrl.attach({
        onCancel: () => io.disconnect(),
      })
    } catch (error) {
      dispatch(UpdateSyncVisitorLocationState({ id: orderId, state: failed(error) }));
    }
  };
}
