/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { ListOperation } from "common/utils";
import { AsyncState, isSuccessful, success } from "common/utils/async-state";
import { Order, PickupOrder } from "order/order.entities";


export const OrderState = createSlice({
  name: "order",
  initialState: {
    sync: {} as AsyncState<Order[]>,
    preparePickupOrder: {} as AsyncState<PickupOrder>,
    cancel: {} as AsyncState<Order>,
    confirm: {} as AsyncState<Order>,
    editEtr: {} as AsyncState<Order>,
    complete: {} as AsyncState<Order>,
    loadOrderHistory: {} as AsyncState<Order[]>,
    loadMoreOrderHistory: {} as AsyncState<Order[]>,
    loadOrderMap: {} as {
      [key: string]: AsyncState<Order>
    },
  },
  reducers: {
    LoadOrderState: (
      state,
      action: { payload: { id: string, state: AsyncState<Order> } },
    ) => {
      state.loadOrderMap[action.payload.id] = action.payload.state;
    },
    SyncOrdersState: (
      state,
      action: { payload: AsyncState<ListOperation<Order>> },
    ) => {
      state.sync = {
        ...state.sync,
        ...action.payload,
        value: action.payload.value?.apply(state.sync.value) || state.sync.value,
      }
      if (isSuccessful(action.payload)) {
        for (let order of state.sync.value!) {
          if (isSuccessful(state.loadOrderMap[order.id])) {
            state.loadOrderMap[order.id] = success(order);
          }
        }
      }
    },
    PreparePickupOrderState: (
      state,
      action: { payload: AsyncState<PickupOrder> },
    ) => {
      state.preparePickupOrder = action.payload;
    },
    ConfirmOrderState: (
      state,
      action: { payload: AsyncState<Order> },
    ) => {
      state.confirm = action.payload;
    },
    EditEtrState: (
      state,
      action: { payload: AsyncState<Order> },
    ) => {
      state.editEtr = action.payload;
    },
    CompleteOrderState: (
      state,
      action: { payload: AsyncState<Order> },
    ) => {
      state.complete = action.payload;
    },
    CancelOrderState: (
      state,
      action: { payload: AsyncState<Order> },
    ) => {
      state.cancel = action.payload;
    },
    LoadOrdersHistoryState: (
      state,
      action: { payload: AsyncState<ListOperation<Order>> },
    ) => {
      state.loadOrderHistory = {
        ...state.loadOrderHistory,
        ...action.payload,
        value: action.payload.value?.apply(state.loadOrderHistory.value) || state.loadOrderHistory.value,
      }
    },
    LoadMoreOrdersHistoryState: (
      state,
      action: { payload: AsyncState<Order[]> },
    ) => {
      state.loadMoreOrderHistory = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  LoadOrderState,
  SyncOrdersState,
  PreparePickupOrderState,
  CancelOrderState,
  CompleteOrderState,
  ConfirmOrderState,
  EditEtrState,
  LoadOrdersHistoryState,
  LoadMoreOrdersHistoryState,
} = OrderState.actions;

export const { reducer: orderReducer, getInitialState } = OrderState;

export type OrderState = ReturnType<typeof OrderState.getInitialState>
