/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
  AsyncAction, CreateListOperation, DeleteListItemOperation,
  SyncController, UpdateListItemOperation,
  UpsertListItemOperation, failed, loading, success,
} from "common/utils";
import { Table } from "../table.entities";
import { SyncTablesState } from '../table.store';


export function SyncTables(ctrl: SyncController): AsyncAction {
  return async (dispatch, _, api) => {
    dispatch(SyncTablesState(loading()));
    try {
      const io = api.sync<Table>("/business/tables", {
        onConnected: (socket) => socket.emit("sync"),
        onEvent: {
          data: (data) => {
            dispatch(SyncTablesState(success(new CreateListOperation(data))));
          },
          create: (item) => {
            dispatch(SyncTablesState(success(new UpsertListItemOperation(item, test))));
          },
          update: (item) => {
            dispatch(SyncTablesState(success(new UpdateListItemOperation(item, test))));
          },
          delete: (item) => {
            dispatch(SyncTablesState(success(new DeleteListItemOperation(item, test))));
          }
        },
        onError(error) {
          dispatch(SyncTablesState(failed(error)));
        },
      });
      ctrl.attach({
        onCancel: () => io.disconnect(),
      })
    } catch (error) {
      dispatch(SyncTablesState(failed(error)));
    }
  };
}

function test(a: Table, b: Table) {
  return a.id === b.id;
}