/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';
import logger from 'loglevel';
import loglevelLocalStorage from 'loglevel-local-storage';
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { BackActivityIndicator, Center } from "common/components";
import "common/i18n";
import { ApiClient } from "common/utils/api";
import "styles.sass";
import createStore from "./app.store";
import reportWebVitals from './reportWebVitals';
import Router from "./router";

const api = new ApiClient();
const store = createStore(api);

loglevelLocalStorage(logger, {
  storeKey: '_logs',
  level: 'debug',
  callOriginal: true,
  prefix(level, ...args) {
    const newArgs = args.map(arg => ((typeof arg === 'object' && !(arg instanceof Date)) ? JSON.stringify(arg) : arg));
    return `[${moment().format('D HH:mm:ss')}] ${level}: ${newArgs.join(' ')}`;
  },
});
logger.debug('logger initialized');

ReactDOM.render(
  <Provider store={store} >
    <BrowserRouter>
      <Suspense fallback={<Center><BackActivityIndicator /></Center>}>
        <Router />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();