/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { AsyncAction, failed, loading, success } from "common/utils";
import { UpdateLoadBasicSalesReportState } from "report/report.store";


export function loadBasicSalesReportAction(since: Date, till: Date): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(UpdateLoadBasicSalesReportState(loading()));
    try {
      const { value } = await api.get(`/reports/sales-basic`, {
        params: {
          since: moment.utc(since).startOf('day').toISOString(),
          till: moment.utc(till).endOf('day').toISOString(),
        },
        headers: {
          'Utc-Time': moment.utc().toISOString(),
        }
      });
      dispatch(UpdateLoadBasicSalesReportState(success(value)));
    } catch (error) {
      dispatch(UpdateLoadBasicSalesReportState(failed(error)));
    }
  };
}
