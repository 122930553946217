/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { DeleteCategoryState } from "../category.store";


export function DeleteCategory(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteCategoryState(loading()));
    try {
      const { value } = await api.delete(`/catalog/categories/${id}`);
      dispatch(DeleteCategoryState(success(value)));
    } catch (error) {
      dispatch(DeleteCategoryState(failed(error)));
    }
  };
}
