/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Dialog, DialogBody, DialogFooter, ResizeSensor } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useEffect, useState } from "react";

import { Button } from "common/components";
import { useSyncOrders } from "order/order.hooks";
import Sidebar from "../../containers/Sidebar";
import Topbar from "../../containers/Topbar";

import styles from "./styles.module.sass";
import { useIdentity } from "auth/auth.hooks";
import ChecklistForm from "common/containers/Checklist";


const dispayMenuAsPopupWidth = 1000;

export default function WorkspaceLayout({
  className, children, ...rest
}: WorkspaceLayoutProps) {
  const classes = [styles.workspace, className];

  // state
  const [sidebarAsPopup, showSidebarAsPopup] =
    useState(window.innerWidth < dispayMenuAsPopupWidth);


  const identity = useIdentity()
  const [isChecklistDialogOpenOnStartup, openChecklistDialogOnStartup] =
    useState(localStorage.getItem(`dontDisplayChecklistOnStartup_${identity!.user.id}`) == null);
  const [isChecklistDialogOpen, openChecklistDialog] = useState(false);

  useEffect(() => {
    if (isChecklistDialogOpenOnStartup)
      localStorage.removeItem(`dontDisplayChecklistOnStartup_${identity!.user.id}`);
    else
      localStorage.setItem(`dontDisplayChecklistOnStartup_${identity!.user.id}`, '1');
  }, [isChecklistDialogOpenOnStartup])

  useEffect(() => {
    if (isChecklistDialogOpenOnStartup && identity?.business && !identity.business!.employee) {
      openChecklistDialog(true);
    }
  }, [identity?.business])

  useSyncOrders();

  const onResize = (entries: ResizeObserverEntry[]) => {
    let displaySidebarAsPopup = false;
    entries.forEach((e) => {
      if (e.contentRect.width < dispayMenuAsPopupWidth) {
        displaySidebarAsPopup = true;
      }
    });
    if (displaySidebarAsPopup !== sidebarAsPopup) {
      showSidebarAsPopup(displaySidebarAsPopup);
    }
  };

  return (
    <ResizeSensor onResize={onResize}>
      <div className={classes.join(" ")} {...rest}>
        <Topbar
          menuButton={!sidebarAsPopup ? undefined :
            <Popover2
              placement="bottom-end"
              interactionKind="click"
              content={
                <Sidebar onOpenChecklist={() => openChecklistDialog(true)} />
              }>
              <Button icon="menu" />
            </Popover2>
          }
        />
        <div className={styles.body}>
          {sidebarAsPopup ? undefined :
            <Sidebar
              className={styles.sidebar}
              onOpenChecklist={() => openChecklistDialog(true)}
            />
          }
          <div className={styles.content}>
            {children}
          </div>
        </div>
        <Dialog
          icon="info-sign"
          title="Onboarding Instructions"
          isOpen={isChecklistDialogOpen}
          onClose={() => openChecklistDialog(false)}>
          <DialogBody>
            <p>Welcome to DishDash! Follow these steps to get your business set up and running smoothly.</p>
            <ChecklistForm />
          </DialogBody>
          <DialogFooter>
            <div className={styles.dontDisplayOnStartup}>
              <input
                id="dontDisplayOnStartup"
                type="checkbox"
                checked={isChecklistDialogOpenOnStartup}
                onChange={(e) => {
                  openChecklistDialogOnStartup(e.target.checked);
                }}
              />
              <label htmlFor="dontDisplayOnStartup">Display on startup</label>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    </ResizeSensor >
  );
}

export type WorkspaceLayoutProps = React.ComponentPropsWithRef<"div"> & {
}