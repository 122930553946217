/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { AsyncAction, failed, loading, success } from "common/utils";
import { Table } from "table/table.entities";
import { SaveTableState } from "../table.store";


export function UpdateTable(id: string, changes: Partial<Table>): AsyncAction {
  return async (dispatch, _getStore, api) => {
    dispatch(SaveTableState(loading()));
    try {
      let data: any, contentType: string;
      if (changes.picture?.file) {
        data = new FormData();
        data.append("picture", changes.picture.file);
        data.append("data", JSON.stringify(changes));
        contentType = 'multipart/form-data';
      } else {
        data = { data: changes };
        contentType = 'application/json'
      }
      const { value } = await api.put(`/business/tables/${id}`, {
        headers: {
          'Content-Type': contentType,
        },
        data,
      })
      dispatch(SaveTableState(success(value)));
    } catch (error) {
      dispatch(SaveTableState(failed(error)));
    }
  };
}
