/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { TagProps } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { Tag } from 'common/components';
import { Order } from "order/order.entities";
import styles from './styles.module.scss';

export default function ({
  className, order, lowercase, children, ...rest
}: OrderPaymentTagProps) {
  const { t } = useTranslation();
  const intent = order.payment?.intent?.status === 'paid'
    ? "success"
    : order.payment?.method == null ||
      order.payment?.intent?.status === 'payment-failed' || order.payment?.intent?.status === 'refunded'
      ? 'danger'
      : order.payment?.method?.offline ||
        order.payment?.intent?.status === 'pending' || order.payment?.intent?.status === 'unpaid'
        ? 'warning'
        : "none";
  return (
    <Tag
      minimal
      className={styles.orderStatusTag}
      intent={intent}>
      {order.payment?.method?.offline
        ? t(`Pricing.PaymentMethods.${order.payment?.method?.type || 'none'}`)
        : t(`Pricing.PaymentStatus.${order.payment?.intent?.status || 'none'}`)}
      {order.payment?.method == null || order.payment?.method?.offline
        ? ''
        : ` (${t(`Pricing.PaymentMethods.${order.payment?.method?.type || 'none'}`)})`}
      {children}
    </Tag>
  );
}

export type OrderPaymentTagProps = TagProps & {
  order: Order,
  lowercase?: boolean,
};