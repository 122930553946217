/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";

import styles from "./styles.module.sass";


export default function Tag({
  className, children, ...rest
}: TagProps) {
  const classes = [styles.tag, className];
  return (
    <Blueprint.Tag className={classes.join(" ")} {...rest}>
      {children}
    </Blueprint.Tag>
  );
}

export type TagProps = Blueprint.TagProps  & {
}