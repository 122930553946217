/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from "react";
import { BiStore } from "react-icons/bi";

import { useSyncBranches } from 'branch/branch.hooks';
import {
  Backout, DropdownField, ExPhoneField, Form, FormField,
  Row,
  SwitchField, TextInputField,
} from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden, useIsReadOnlyField } from "common/utils";
import { Employee, employeeRoles } from "employee/employee.entities";
import { ExImageField } from "media/components";
import styles from "./styles.module.scss";


export default function EmployeeForm<T extends Employee>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: EmployeeFormProps<T>) {
  const classes = useMemo(() => [styles.employeeForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const isReadonly = useIsReadOnlyField(rest);

  const { branches } = useSyncBranches();

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("active")}
        label={["Active"]}
        value={value?.active}
        error={errors?.active}
        readOnly={readOnly}
        hidden={isHidden("active")}
        onChange={onChange}
      />
      <DropdownField
        name={fieldName("role")}
        label={["Role"]}
        value={value?.role}
        error={errors?.role}
        options={employeeRoles.map(role => ({
          id: role,
          value: role,
          name: _.startCase(role),
        }))}
        readOnly={readOnly}
        hidden={isHidden("role")}
        onChange={onChange}
      />
      <ExImageField
        name={fieldName("picture")}
        label={["Picture"]}
        value={value?.picture}
        error={errors?.picture}
        readOnly={readOnly}
        hidden={isHidden("picture")}
        onChange={onChange}
        frameProps={{
          height: 150,
          width: 150,
        }} />
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <Row className={styles.row}>
        <ExPhoneField
          name="phone"
          label={["User"]}
          placeholder={"Phone Number"}
          value={value?.phone}
          error={errors?.phone}
          hidden={isHidden("phone")}
          readOnly={isReadonly("phone")}
          onChange={e => {
            onChange(e, {
              target: {
                name: 'email',
                value: null
              }
            });
          }}
        />
        {isHidden('phone') || isHidden('email') ? null : (
          <span>OR</span>
        )}
        <TextInputField
          name="email"
          placeholder="Email"
          value={value?.email}
          error={errors?.email}
          readOnly={isReadonly("email")}
          hidden={isHidden("email")}
          onChange={e => {
            onChange(e, {
              target: {
                name: 'phone',
                value: null
              }
            });
          }}
        />
      </Row>
      <FormField
        label={["Branches"]}
        error={errors?.branches}
        hidden={isHidden("pincode")}>
        <Form>
          {branches.length === 0
            ? <Backout
              compact
              icon={<BiStore size={35} />}
              message={["You don't have any branches yet"]}
            />
            : branches.map((branch) =>
              <SwitchField
                key={branch.id}
                name={branch.id}
                label={branch.name}
                value={value?.branches.includes(branch.id) === true}
                style={{ marginBottom: 10 }}
                onChange={({ target }) => {
                  let newBranches: string[];
                  if (target.value) {
                    newBranches = value?.branches?.slice() || [];
                    newBranches.push(target.name);
                  } else {
                    newBranches = value!.branches.filter(id => id !== target.name)
                  }
                  onChange({
                    target: {
                      name: fieldName("branches"),
                      value: newBranches,
                    }
                  })
                }}
              />
            )}
        </Form>
      </FormField>
      {children}
    </Form>
  );
}

export type EmployeeFormProps<T extends Employee> = EntityFormProps<T> & {
};