/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect, useMemo } from "react";

import { SyncCategories } from "category/actions/sync-categories.action";
import { Category } from "category/category.entities";
import { isEmpty, SyncController, useAppState } from "common/utils";


export function useSyncCategories<T extends Category = Category>(opts: {
  auto?: boolean,
  find?: (value: Category, index: number, array: Category[]) => boolean,
  filter?: (value: Category, index: number, array: Category[]) => boolean,
} = {}) {
  const [syncCategoriesState, dispatch] = useAppState((state) => state.category.sync);

  const syncCategories = useCallback(() => {
    const unsub = new SyncController();
    dispatch(SyncCategories(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncCategoriesState))
      syncCategories();
  }, [opts.auto, syncCategoriesState])

  const categories = useMemo(() =>
    ((opts.filter == null
      ? syncCategoriesState.value
      : syncCategoriesState.value?.filter(opts.filter)) || []) as T[],
    [syncCategoriesState, opts.filter]);

  const category = useMemo(() =>
    opts.find == null
      ? null
      : syncCategoriesState.value?.find(opts.find) as T,
    [syncCategoriesState, opts.find]);

  return { syncCategoriesState, syncCategories, categories, category };
}