/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useState } from "react";
import { FiUsers } from "react-icons/fi"; 
import { useNavigate } from "react-router-dom";

import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader,
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { AsyncStateBuilder } from 'common/utils';
import EmployeeCard from 'employee/components/EmployeeCard';
import { Employee } from 'employee/employee.entities';
import { useSyncEmployees } from "employee/employee.hooks";


export default function EmployeesPage() {
  // contexts
  const navigate = useNavigate()

  // state
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  // api state
  const { syncEmployeesState, employees } = useSyncEmployees({
    filter: (employee) => _.isEmpty(searchQuery) ||
      _.values(employee).join(" ").toLowerCase().includes(searchQuery!),
  });

  return (
    <>
      <PageHeader
        icon={<FiUsers size={20} style={{ marginRight: 2 }} />}
        title={['Staff']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text="Add"
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder<Employee[]>
          state={syncEmployeesState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <div style={{ marginTop: 150 }}>
              <Backout
                icon={<FiUsers style={{ marginTop: -3, marginLeft: -2 }} />}
                title="No Employees Yet">
                <div style={{ marginTop: 20 }}>
                  <Button
                    outlined
                    text={["Add"]}
                    onClick={() => navigate("/employees/new")} />
                </div>
              </Backout>
            </div>
          )}
          onValueNotEmpty={() =>
            <Grid md={3} sm={2} xs={1} gap={20} >
              {employees.map(employee =>
                <EmployeeCard
                  key={employee.id}
                  value={employee}
                  onClick={() => navigate(employee.id!)}
                />
              )}
            </Grid>
          }
        />
      </PageContent>
    </>
  );
}
