/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError, Button,
  Card, CardContent, CardHeader,
  Column,
  Grid, PageContent, PageHeader, PageNotFound, Toast
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors,
} from 'common/utils';
import { CreateTable } from 'table/actions/create-table.action';
import { DeleteTable } from 'table/actions/delete-table.action';
import { UpdateTable } from 'table/actions/update-table.action';
import TableForm from 'table/components/TableForm';
import { Table } from 'table/table.entities';
import { useSyncTables } from 'table/table.hooks';

export default function TablePage() {
  const { id } = useParams();
  const { pathname, state } = useLocation();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();

  // store
  const { syncTablesState, table } = useSyncTables({
    find: table => table.id === id,
  });
  const [saveState, dispatch] = useAppState((store) => store.table.save);
  const [deleteState] = useAppState((store) => store.table.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } = useFormData<Table>(table, {
    active: true,
    status: 'free',
    seats: 0,
  }, [table]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (id === "new") {
          navigate("/tables");
          Toast.showSuccess({ message: `A new table "${saveState.value!.name}" has been added` });
        } else {
          Toast.showSuccess({ message: "The table has been updated" });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: `The table "${deleteState.value!.name}" has been removed` });
        navigate('/tables');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={table?.name || "New Table"}
        backButtonPath={state?.backpath || "/tables"}>
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(CreateTable(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Save"
          intent="success"
          onClick={() => {
            const update = { ...changes };
            if (update.picture?.frame) {
              update.picture.frame = {
                ...formData.picture!.frame,
                ...update.picture.frame,
              }
            }
            dispatch(UpdateTable(id!, update));
          }}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={table == null}
                disabled={table == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={syncTablesState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!table && id !== "new")
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={["General"]} />
                  <CardContent>
                    <TableForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState)}
                      onChange={onChange}
                      hiddenFields={["picture", 'waiter']}
                    />
                  </CardContent>
                </Card>
                <Column gap={20}>
                  <Card>
                    <CardHeader title={["Picture"]} />
                    <CardContent>
                      <TableForm
                        value={formData}
                        errors={formErrors}
                        readOnly={isLoading(saveState)}
                        onChange={onChange}
                        visibleFields={["picture"]}
                      />
                    </CardContent>
                  </Card>
                  <Card>
                    <CardHeader title={["Waiter"]} />
                    <CardContent>
                      <TableForm
                        value={formData}
                        errors={formErrors}
                        readOnly={isLoading(saveState)}
                        onChange={onChange}
                        visibleFields={["waiter"]}
                      />
                    </CardContent>
                  </Card>
                </Column>
              </Grid>
            );
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteTable(id!))}>
          <p>Delete this table?</p>
        </Alert>
      </PageContent >
    </>
  );
}
