/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, CardItem, Column, Row, Tag } from 'common/components';
import Card, { CardProps } from "common/components/cards/Card";
import { formatExPhone } from 'common/utils';
import { Employee } from "../../employee.entities";
import styles from "./styles.module.scss";


export default function ({
  className, value, onClick, hideRole, children, ...rest
}: EmployeeCardProps) {
  const { t } = useTranslation();
  const classNames = useMemo(() =>
    [styles.employeeCard, className].join(" ").trim(),
    [className]);

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classNames}
      onClick={onClick}
      {...rest}>
      <Row>
        <Avatar
          size={55}
          name={value.name}
          picture={value.picture?.small?.url}
          grayscale={value.active != true}
        />
        <Column>
          <h4>
            {value.name}
            {!hideRole && (
              <Tag
                intent={
                  value.active != true ? 'none' :
                    value.role === 'admin' ? 'danger' : 'primary'
                }>
                {value.role}
              </Tag>
            )}
          </h4>
          <CardItem
            icon="phone"
            hidden={value.phone == null}
            left={formatExPhone(value.phone)} />
        </Column>
      </Row>
      {children}
    </Card>
  );
}

export type EmployeeCardProps = CardProps & {
  value: Employee,
  hideRole?: boolean,
};