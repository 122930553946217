/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useMemo } from "react";

import { TimeSchedule } from "common/common.entities";
import { Button } from "common/components";
import OpeningHoursForm from "common/components/datetime/OpeningHoursForm";
import { Form, FormField } from "common/components/form";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import styles from "./styles.module.scss";


export default function <T extends TimeSchedule>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: TimeScheduleFormProps<T>) {
  const classes = useMemo(() => [styles.timeScheduleForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const clearDay = useCallback((day) => {
    onChange({ target: { name: fieldName(day), value: null } });
  }, [onChange])

  return (
    <Form className={classes} {...rest}>
      <FormField
        className={styles.openingHoursField}
        label="Monday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.mon == null}
            onClick={() => clearDay("mon")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("mon")}
          value={value?.mon}
          errors={errors?.mon}
          readOnly={readOnly}
          hidden={isHidden("mon")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        className={styles.openingHoursField}
        label="Tuesday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.tue == null}
            onClick={() => clearDay("tue")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("tue")}
          value={value?.tue}
          errors={errors?.tue}
          readOnly={readOnly}
          hidden={isHidden("tue")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        className={styles.openingHoursField}
        label="Wednesday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.wed == null}
            onClick={() => clearDay("wed")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("wed")}
          value={value?.wed}
          errors={errors?.wed}
          readOnly={readOnly}
          hidden={isHidden("wed")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        className={styles.openingHoursField}
        label="Thursday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.thu == null}
            onClick={() => clearDay("thu")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("thu")}
          value={value?.thu}
          errors={errors?.thu}
          readOnly={readOnly}
          hidden={isHidden("thu")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        className={styles.openingHoursField}
        label="Friday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.fri == null}
            onClick={() => clearDay("fri")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("fri")}
          value={value?.fri}
          errors={errors?.fri}
          readOnly={readOnly}
          hidden={isHidden("fri")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        className={styles.openingHoursField}
        label="Saturday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.sat == null}
            onClick={() => clearDay("sat")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("sat")}
          value={value?.sat}
          errors={errors?.sat}
          readOnly={readOnly}
          hidden={isHidden("sat")}
          onChange={onChange}
        />
      </FormField>
      <FormField
        className={styles.openingHoursField}
        label="Sunday"
        actions={
          <Button
            minimal
            icon='cross'
            hidden={value?.sun == null}
            onClick={() => clearDay("sun")}
          />
        }>
        <OpeningHoursForm
          name={fieldName("sun")}
          value={value?.sun}
          errors={errors?.sun}
          readOnly={readOnly}
          hidden={isHidden("sun")}
          onChange={onChange}
        />
      </FormField>
      {children}
    </Form >
  );
}

export type TimeScheduleFormProps<T extends TimeSchedule> = EntityFormProps<T> & {
};