/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from "react";

import { Form, InfoTooltip, SwitchField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden, useIsReadOnlyField } from "common/utils";
import { MerchantAccount } from "merchant/merchant.entities";
import styles from "./styles.module.sass";


export default function MerchantAccountForm<T extends MerchantAccount>({
  className, value, errors, onChange, children, ...rest
}: MerchantAccountFormProps<T>) {
  const classes = useMemo(() => [styles.merchantaccountForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const isReadOnly = useIsReadOnlyField(rest);

  return (
    <Form className={classes} {...rest}>
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={isReadOnly("name")}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("displayName")}
        label={["Business Display Name"]}
        actions={
          <InfoTooltip content={'This name appears in ApplePay and GooglePay payment details'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
        value={value?.displayName}
        error={errors?.displayName}
        readOnly={isReadOnly("displayName")}
        hidden={value?.type === 'basic' || isHidden("displayName")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("currency")}
        label={["Currency"]}
        value={value?.currency}
        error={errors?.currency}
        readOnly={isReadOnly("currency")}
        hidden={isHidden("currency")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("uponArrivalPayments")}
        label={["Upon-Arrival Payments"]}
        value={value?.uponArrivalPayments === true}
        error={errors?.uponArrivalPayments}
        readOnly={isReadOnly("uponArrivalPayments")}
        disabled={value?.uponArrivalPayments === null}
        hidden={isHidden("uponArrivalPayments")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("atCounterPayments")}
        label={["At Counter Payments"]}
        value={value?.atCounterPayments === true}
        error={errors?.atCounterPayments}
        readOnly={isReadOnly("atCounterPayments")}
        disabled={value?.atCounterPayments === null}
        hidden={isHidden("atCounterPayments")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("cardPayments")}
        label={["Card Payments"]}
        value={value?.cardPayments === true}
        error={errors?.cardPayments}
        readOnly={isReadOnly("cardPayments")}
        disabled={value?.cardPayments === null}
        hidden={true || isHidden("cardPayments")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("applePay")}
        label={["Apple Pay"]}
        value={value?.applePay === true}
        error={errors?.applePay}
        readOnly={isReadOnly("applePay")}
        disabled={value?.applePay === null}
        hidden={true || isHidden("applePay")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("googlePay")}
        label={["Google Pay"]}
        value={value?.googlePay === true}
        error={errors?.googlePay}
        readOnly={isReadOnly("googlePay")}
        disabled={value?.googlePay === null}
        hidden={true || isHidden("googlePay")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("paymentProcessingFee")}
        label={["Pass Stripe Processing Fee to Customers"]}
        value={value?.paymentProcessingFee === true || _.isObject(value?.paymentProcessingFee)}
        error={errors?.paymentProcessingFee}
        readOnly={isReadOnly("paymentProcessingFee")}
        // hidden={true}
        hidden={value?.type != 'stripe' || isHidden("paymentProcessingFee")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type MerchantAccountFormProps<T extends MerchantAccount> = EntityFormProps<T> & {
};