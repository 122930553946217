/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError, Button,
  Card, CardContent, CardHeader,
  Grid, PageContent, PageHeader, PageNotFound, Toast
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors,
} from 'common/utils';
import { CreateEmployee } from 'employee/actions/create-employee.action';
import { DeleteEmployee } from 'employee/actions/delete-employee.action';
import { UpdateEmployee } from 'employee/actions/update-employee.action';
import EmployeeForm from 'employee/components/EmployeeForm';
import { Employee } from 'employee/employee.entities';
import { useSyncEmployees } from 'employee/employee.hooks';

export default function EmployeePage() {
  const { id } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();

  // store
  const { syncEmployeesState, employee } = useSyncEmployees({
    find: employee => employee.id === id,
  });
  const [saveState, dispatch] = useAppState((store) => store.employee.save);
  const [deleteState] = useAppState((store) => store.employee.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } = useFormData<Employee>(employee, {
    active: true,
    role: 'manager',
    branches: [],
  }, [employee]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (id === "new") {
          navigate("/employees");
          Toast.showSuccess({ message: `A new employee "${saveState.value!.name}" has been added` });
        } else {
          Toast.showSuccess({ message: "The employee has been updated" });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: `The employee "${deleteState.value!.name}" has been removed` });
        navigate('/employees');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={employee?.name || "New Employee"}
        backButtonPath={state?.backpath || "/employees"}>
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(CreateEmployee(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Save"
          intent="success"
          onClick={() => {
            const update = { ...changes };
            if (update.picture?.frame) {
              update.picture.frame = {
                ...formData.picture!.frame,
                ...update.picture.frame,
              }
            }
            dispatch(UpdateEmployee(id!, update));
          }}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={employee == null}
                disabled={employee == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={syncEmployeesState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!employee && id !== "new")
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={["General"]} />
                  <CardContent>
                    <EmployeeForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState)}
                      readOnlyFields={formData.id ? ["email", "phone"] : []}
                      onChange={onChange}
                      hiddenFields={["picture"]}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader title={["Picture"]} />
                  <CardContent>
                    <EmployeeForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState)}
                      onChange={onChange}
                      visibleFields={["picture"]}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteEmployee(id!))}>
          <p>Delete this employee?</p>
        </Alert>
      </PageContent>
    </>
  );
}
