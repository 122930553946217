/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { LoadOrderState } from "../order.store";


export function loadOrder(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(LoadOrderState({ id, state: loading() }));

    const fromSync = store().order.sync.value?.find(order => order.id === id);
    if (fromSync) {
      setTimeout(() => {
        dispatch(LoadOrderState({ id, state: success(fromSync) }));
      }, 10);
      return;
    }
    try {
      let { value } = await api.get(`/orders/${id}`);
      if (value == null) {
        const fromHistory = await api.get(`/order-history/${id}`);
        value = fromHistory.value;
      }
      dispatch(LoadOrderState({ id, state: success(value) }));
    } catch (error) {
      dispatch(LoadOrderState({ id, state: failed(error) }));
    }
  };
}