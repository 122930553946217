/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { DeleteCatalogState } from "../catalog.store";


export function DeleteCatalog(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteCatalogState(loading()));
    try {
      const { value } = await api.delete(`/catalogs/${id}`);
      dispatch(DeleteCatalogState(success(value)));
    } catch (error) {
      dispatch(DeleteCatalogState(failed(error)));
    }
  };
}
