import * as bp from '@blueprintjs/core';
import { useEffect, useMemo, useRef } from "react";

import styles from "./styles.module.scss";


export default function Tabs({
  className, stickToPageHeader = true, ...rest
}: TabsProps) {

  let pageHeaderOffsets: {
    height: number,
    width: number,
    left: number,
  } | null = null;

  useEffect(() => {
    if (!stickToPageHeader) return;
    const el = document.getElementById("page-header");
    if (el) {
      el.classList.add(styles.removePageHeaderShadows);
      pageHeaderOffsets = {
        height: el.offsetHeight,
        width: el.offsetWidth,
        left: el.offsetLeft,
      }
    }
  }, [stickToPageHeader]);

  const observerClasses = useMemo(() => {
    const cls = [styles.observer];
    if (stickToPageHeader) cls.push(styles.sticky);
    return cls.join(' ').trim();
  }, [className]);
  const classes = useMemo(() => {
    const cls = [styles.tabs];
    if (className) cls.push(className);
    if (stickToPageHeader) cls.push(styles.stickyTabList);
    return cls.join(' ').trim();
  }, [className]);

  const observedRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (pageHeaderOffsets && observedRef.current) {
      const observedEl = observedRef.current;
      observedEl.style.top = `${pageHeaderOffsets.height - 13}px`;
      const parentEl = observedEl.parentNode! as HTMLElement;
      const tablistEl = parentEl.children[2].children[0] as HTMLElement;
      tablistEl.style.top = `${pageHeaderOffsets.height - 13}px`;
      const panelEl = parentEl.children[1] as HTMLElement;
      panelEl.style.marginLeft = `${pageHeaderOffsets.left - panelEl.offsetLeft}px`
      panelEl.style.width = `${pageHeaderOffsets.width}px`;
      panelEl.style.height = `${pageHeaderOffsets.height + 30}px`;
      const rootMargin = `-${pageHeaderOffsets.height}px`;
      const observer = new IntersectionObserver(([e]) => {
        const stuck = e.intersectionRatio < 1;
        panelEl.toggleAttribute('stuck', stuck);
      }, { threshold: [0], rootMargin });
      observer.observe(observedRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [observedRef, pageHeaderOffsets])

  return (
    <>
      <div className={observerClasses} ref={observedRef} />
      <div className={styles.panel} />
      <bp.Tabs className={classes} {...rest} />
    </>
  );
}

export type TabsProps = bp.TabsProps & {
  className?: string;
  stickToPageHeader?: boolean;
}