/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Business, businessCategories } from "business/business.entities";
import { EntityForm, InfoTooltip } from "common/components";
import { EntityFormProps } from "common/components/form/EntityForm";
import { isUseless, useAppState } from "common/utils";
import CountryDropdownField from "directory/components/CountryDropdownField";
import { useGetCountries } from "directory/directory.hooks";
import { GeocodeIpAction } from "geo/actions/geocode-ip.action";
import styles from "./styles.module.sass";


export default function BusinessForm<T extends Business>({
  className, readOnly, value, errors, onChange,
  children, fields, ...rest
}: BusinessFormProps<T>) {
  const { t } = useTranslation();

  const classes = useMemo(() =>
    [styles.baseBusinessForm, className].join(" "),
    [className]);

  const [geocodeIpState, dispatch] = useAppState((state) => state.geo.geocodeIp);

  const { country } = useGetCountries({
    auto: value?.country == null && geocodeIpState.value != null,
    find: (item) => item.code === geocodeIpState?.value?.countryCode
  }, [value?.country, geocodeIpState])

  useEffect(() => {
    if (value?.country == null) {
      if (isUseless(geocodeIpState)) {
        dispatch(GeocodeIpAction())
      } else if (country) {
        onChange({
          target: {
            name: 'country',
            value: country.id,
          },
        });
        if (value?.phone?.code == null) {
          setTimeout(() => {
            onChange({
              target: {
                name: 'phone',
                value: {
                  code: country.dialingCode,
                  number: value?.phone?.number || '',
                }
              },
            });
          }, 300);
        }
      }
    }
  }, [value?.country, country])

  return (
    <EntityForm
      className={classes}
      value={value}
      errors={errors}
      onChange={onChange}
      readOnly={readOnly}
      fields={[{
        type: "input",
        name: "name",
        label: ["Business Name"],
      }, {
        type: "textarea",
        name: "description",
        label: ["Description"],
      }, {
        type: "input",
        name: "email",
        label: ["Contact Email"],
        autoCapitalize: 'none',
        actions: (
          <InfoTooltip
            content={'May be used by DishDash for critical account notifications and communication'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        )
      }, {
        type: "ex-phone",
        name: "phone",
        label: ["Contact Phone"],
        actions: (
          <InfoTooltip
            content={'May be used by DishDash for critical account notifications and communication'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        )
      }, {
        type: 'custom',
        name: 'country',
        label: ["Country"],
        render: (props) => (
          <CountryDropdownField {...props} />
        )
      }, {
        type: 'dropdown',
        name: 'category',
        label: ["Category"],
        options: businessCategories.map(id => ({
          id: id,
          name: t(`BusinessCategories.${id}`),
          value: id,
        }))
      }]}
      {...rest}>
      {children}
    </EntityForm>
  );
}

export type BusinessFormProps<T extends Business> = EntityFormProps<T> & {
};