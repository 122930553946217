/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { LoginState } from "auth/auth.store";
import { AsyncAction, failed, loading, success } from "common/utils";


export function LoginWithOtpAction(token: string, code: string): AsyncAction {
  return async (dispatch, _getState, api) => {
    dispatch(LoginState(loading()));
    try {
      const { value } = await api.get("/auth/otp/login", {
        params: { token, code }
      });
      dispatch(LoginState(success(value!)));
    } catch (error) {
      dispatch(LoginState(failed(error)));
    }
  };
}