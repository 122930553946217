/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import moment from 'moment';

import { useCallback, useEffect, useMemo } from "react";

import { isEmpty, SyncController, useAppState } from "common/utils";
import * as historyActions from "order/actions/load-order-history.action";
import { SyncOrders } from "order/actions/sync-orders.action";
import { Order, PickupOrder } from "order/order.entities";


export function useSyncOrders<T extends Order = Order>(opts: {
  auto?: boolean,
  find?: (value: Order, index: number, array: Order[]) => boolean,
  filter?: (value: Order, index: number, array: Order[]) => boolean,
} = {}, deps: any[] = []) {
  const [syncOrdersState, dispatch] = useAppState((state) => state.order.sync);

  const syncOrders = useCallback(() => {
    const unsub = new SyncController();
    dispatch(SyncOrders(unsub));
    return unsub.cancel;
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(syncOrdersState))
      syncOrders();
  }, [opts.auto !== false, isEmpty(syncOrdersState)])

  const orders = useMemo(() =>
    ((opts.filter == null
      ? syncOrdersState.value
      : syncOrdersState.value?.filter(opts.filter)) || []) as T[],
    [syncOrdersState, opts.filter, ...deps]);

  const order = useMemo(() =>
    opts.find == null
      ? null
      : syncOrdersState.value?.find(opts.find) as T,
    [syncOrdersState, opts.find, ...deps]);

  return { syncOrdersState, syncOrders, orders, order };
}


export function useLoadOrderHistory(opts: {
  auto?: boolean,
  find?: (value: Order, index: number, array: Order[]) => boolean,
  filter?: (value: Order, index: number, array: Order[]) => boolean,
  pageSize: number,
} = { pageSize: 50 }, deps: any[] = []) {
  const [loadOrderHistoryState, dispatch] = useAppState((state) => state.order.loadOrderHistory);
  const [loadMoreOrderHistoryState] = useAppState((state) => state.order.loadMoreOrderHistory);

  const loadOrderHistory = useCallback((query?: string) =>
    dispatch(historyActions.loadOrderHistory(opts.pageSize, query)), []);
  const loadMoreOrderHistory = useCallback((from: number) =>
    dispatch(historyActions.loadMoreOrderHistory(opts.pageSize, from)), []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(loadOrderHistoryState))
      loadOrderHistory();
  }, [opts.auto, loadOrderHistoryState])

  const orders = useMemo(() =>
  ((opts.filter == null
    ? loadOrderHistoryState.value
    : loadOrderHistoryState.value?.filter(opts.filter)) || []),
    [loadOrderHistoryState, opts.filter, ...deps]);

  const order = useMemo(() =>
    opts.find == null ? null : loadOrderHistoryState.value?.find(opts.find),
    [loadOrderHistoryState, opts.find, ...deps]);

  return {
    loadOrderHistoryState,
    loadMoreOrderHistoryState,
    loadMoreOrderHistory,
    loadOrderHistory,
    orders,
    order
  };
}

export const useCreatedAgo = (order?: Order | null) => useMemo(() => {
  if (!order) return null;

  const mins = moment().diff(moment(order.createdAt).local(), 'minutes');
  if (mins == 0) return 'moment ago';
  if (mins === 1) return `one min ago`;
  if (mins < 60) return `${mins} mins ago`;
  const hours = moment().diff(moment(order.createdAt).local(), 'hours');
  if (hours === 1) return `one hour ago`;
  if (hours <= 24) return `${hours} hours ago`;
  const days = moment().diff(moment(order.createdAt).local(), 'days');
  if (days === 1) return `one day ago`;
  return `${days} days ago`;
}, [order?.createdAt]);

export const useFormatEtrEta = () => useCallback((when: Date, prefix: string, alreadyText: string | ((minsAgo: number) => string)) => {
  const mins = moment(when).local().diff(moment(), 'minutes');
  if (mins <= 0) return _.isFunction(alreadyText) ? alreadyText(mins) : alreadyText;
  if (mins === 1) return `${prefix} one minute`;
  if (mins < 60) return `${prefix} ${mins} minutes`;
  const hours = moment(when).local().diff(moment(), 'hours');
  if (hours === 1) return `${prefix} one hour`;
  if (hours <= 24) return `${prefix} ${hours} hours`;
  const days = moment(when).local().diff(moment(), 'days');
  if (days === 1) return `${prefix} one day`;
  return `${prefix} ${days} days`;
}, []);

export const useEtrString = (order?: Order | null, prefix: string = "Ready in ", alreadyText: string = "Ready") => {
  const formatEtrEta = useFormatEtrEta();
  const value = useMemo(() => {
    if (order?.etr == null) return null;
    return formatEtrEta(order.etr, prefix, alreadyText);
  }, [order?.etr]);
  return value;
};

export const usePreferredEtrString = (order?: PickupOrder | null, prefix: string = " ", alreadyText: string | ((minsAgo: number) => string) = "") => {
  const formatEtrEta = useFormatEtrEta();
  const value = useMemo(() => {
    if (order?.preferredEtr == null) return null;
    return formatEtrEta(order.preferredEtr, prefix, alreadyText);
  }, [order?.preferredEtr]);
  return value;
};

export const useEtaString = (order?: PickupOrder | null, prefix: string = "Arriving in ", alreadyText: string = "Arriving") => {
  const formatEtrEta = useFormatEtrEta();
  const value = useMemo(() => {
    if (order == null) return null;
    if (order.visitorActivity?.status === 'arrived') return 'Arrived';
    if (_.get(order, 'visitorActivity.directions.routes.0.eta') == null) return null;
    return formatEtrEta(moment.utc().add(
      (order as PickupOrder).visitorActivity!.directions!.routes![0].eta!, 'seconds').toDate(),
      prefix, alreadyText);
  }, [order]);
  return value;
};
