/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { Branch } from "branch/branch.entities";
import { CurrentBusinessChanged } from "business/business.store";
import { ListOperation } from "common/utils";
import { AsyncState, isSuccessful } from "common/utils/async-state";


export const BranchStore = createSlice({
  name: "branch",
  initialState: {
    sync: {} as AsyncState<Branch[]>,
    save: {} as AsyncState<{ status: string, entity: Branch }>,
    delete: {} as AsyncState<Branch>,
  },
  reducers: {
    SyncBranchesState: (
      state,
      action: { payload: AsyncState<ListOperation<Branch>> },
    ) => {
      state.sync = {
        ...state.sync,
        ...action.payload,
        value: action.payload.value?.apply(state.sync.value) || state.sync.value,
      }
    },
    SaveBranchState: (
      state,
      action: { payload: AsyncState<{ status: string, entity: Branch }> },
    ) => {
      state.save = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.entity.id);
        const newSyncValue = state.sync.value!.slice();
        if (idx === -1) {
          newSyncValue.push(action.payload.value!.entity);
        } else {
          newSyncValue[idx] = action.payload.value!.entity;
        }
        state.sync.value = newSyncValue;
      }
    },
    DeleteBranchState: (
      state,
      action: { payload: AsyncState<Branch> },
    ) => {
      state.delete = action.payload;
      if (isSuccessful(action.payload) && isSuccessful(state.sync)) {
        const idx = state.sync.value!.findIndex(item => item.id === action.payload.value!.id);
        if (idx !== -1) {
          const newSyncValue = state.sync.value!.slice();
          newSyncValue.splice(idx, 1);
          state.sync.value = newSyncValue;
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  SyncBranchesState,
  SaveBranchState,
  DeleteBranchState,
} = BranchStore.actions;

export const { reducer: branchReducer, getInitialState } = BranchStore;

export type BranchState = ReturnType<typeof BranchStore.getInitialState>
