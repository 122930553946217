/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CreateCategory } from 'category/actions/create-category.action';
import { DeleteCategory } from 'category/actions/delete-category.action';
import { UpdateCategory } from 'category/actions/update-category.action';
import { Category } from 'category/category.entities';
import { useSyncCategories } from 'category/category.hooks';
import CategoryForm from 'category/components/CategoryForm';
import {
  BackActivityIndicator, BackAsyncError, Backout, Button, Card, CardContent,
  CardHeader, Column, DragAndDropOrderList, Grid, PageContent, PageHeader,
  PageNotFound, Row, SelectField, Toast,
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors,
} from 'common/utils';
import ProductCard from 'product/components/ProductCard';
import { Product } from 'product/product.entities';
import { useSyncProducts } from 'product/product.hooks';
import ManageSubscriptionDialog from 'subscription/components/ManageSubscriptionDialog';
import SubscriptionPlansDialog from 'subscription/components/SubscriptionPlansDialog';
import styles from './styles.module.scss';


export default function CategoryPage() {
  const { id } = useParams();
  const initial = useInitial();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // store
  const { syncCategoriesState, category } = useSyncCategories({
    find: category => category.id === id,
  });

  const [saveState, dispatch] = useAppState((store) => store.category.save);
  const [deleteState] = useAppState((store) => store.category.delete);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<Category>(category, () => ({
      available: true,
      products: [],
    }), [category]);
  useStateErrors(saveState, setFormErrors);

  // store
  const { syncProductsState, products } = useSyncProducts({
    filter: (product) => formData.products.includes(product.id!) === true,
  })

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isProductPickerOpen, setProductPickerOpen] = useState(false);
  const [isSubscriptionPlansDialogVisible, setSubscriptionPlansDialogVisible] = useState(false);
  const [isManageSubscriptionDialogVisible, setManageSubscriptionDialogVisible] = useState(false);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (saveState.value?.status === 'no-subscription') {
          setTimeout(() => setSubscriptionPlansDialogVisible(true), 800);
          return
        }
        if (saveState.value?.status === 'upgrade-subscription') {
          setTimeout(() => setManageSubscriptionDialogVisible(true), 800);
          return
        }
        if (id === "new") {
          navigate("/categories");
          Toast.showSuccess({ message: `A new category "${saveState.value!.entity.name}" has been added` });
        } else {
          Toast.showSuccess({ message: "The category has been updated" });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: `The category "${deleteState.value!.name}" has been removed` });
        navigate('/categories');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={category?.name || "New Category"}
        backButtonPath="/categories">
        <Button
          text="Create"
          intent="success"
          onClick={() => dispatch(CreateCategory(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Save"
          intent="success"
          onClick={() => dispatch(UpdateCategory(id!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={category == null}
                disabled={category == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={syncCategoriesState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onSuccess={() =>
            <AsyncStateBuilder state={syncProductsState}
              onLoading={() => <BackActivityIndicator />}
              onError={(error) => <BackAsyncError error={error} />}
              onValue={() => {
                if (!category && id !== "new")
                  return <PageNotFound />
                return (
                  <Grid md={2} xs={1} gap={20}>
                    <Card>
                      <CardContent>
                        <CategoryForm
                          value={formData}
                          errors={formErrors}
                          readOnly={isLoading(saveState)}
                          onChange={onChange}
                        />
                      </CardContent>
                    </Card>
                    <Card>
                      <CardHeader title={["Products"]} />
                      <CardContent className={styles.productCards}>
                        <SelectField<Product>
                          name="productPicker"
                          value={undefined}
                          items={syncProductsState.value!.filter(p => formData.products.includes(p.id!) === false)}
                          popoverProps={{
                            isOpen: isProductPickerOpen,
                            onInteraction: setProductPickerOpen,
                          }}
                          onChange={(event) => {
                            const newProducts = formData.products.slice();
                            newProducts.push(event.target.value.id!);
                            onChange({
                              target: {
                                name: "products",
                                value: newProducts,
                              }
                            })
                          }}
                          buttonRenderer={(product) => product == null ? "Add a Product" :
                            <MenuItem icon="box" label={product!.name} />
                          }
                          itemPredicate={(query, product) =>
                            _.values(product).join(' ').toLowerCase().includes(query.toLowerCase())
                          }
                          itemRenderer={(product, props) =>
                            <ProductCard
                              key={product.id}
                              className={styles.selectProductCard}
                              value={product}
                              onClick={props.handleClick}
                              onFocus={props.handleFocus}
                            />
                          } />
                        {products.length === 0
                          ? <Backout message={["No Products"]} />
                          : <DragAndDropOrderList<Product>
                            id='productList'
                            name='products'
                            values={formData.products
                              .map(id => products.find(p => p.id === id)!)
                              .filter(product => product != null)}
                            onChange={({ target: { name, value } }) =>
                              onChange({
                                target: {
                                  name,
                                  value: value.map(p => p.id),
                                }
                              })
                            }
                            renderItem={(product) => (
                              <Row mainAxis='space-between' crossAxis='center'>
                                <ProductCard className='draggable' value={product} />
                                <Column className={styles.productActions}>
                                  <Button
                                    minimal
                                    icon="share"
                                    onClick={() => window.open(`/products/${product.id}`, "_blank")}
                                  />
                                  <Button
                                    minimal
                                    icon="trash"
                                    onClick={() => {
                                      const newProducts = formData.products.filter(pid => product.id !== pid);
                                      onChange({
                                        target: {
                                          name: "products",
                                          value: newProducts,
                                        }
                                      })
                                    }}
                                  />
                                </Column>
                              </Row>
                            )}
                          />
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                );
              }}
            />
          }
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteCategory(id!))}>
          <p>Delete this category?</p>
        </Alert>
        <SubscriptionPlansDialog
          title="To make this category available you need a Subscription"
          isOpen={isSubscriptionPlansDialogVisible}
          onClose={() => setSubscriptionPlansDialogVisible(false)} />
        <ManageSubscriptionDialog
          title="My Subscription"
          isOpen={isManageSubscriptionDialogVisible}
          onClose={() => setManageSubscriptionDialogVisible(false)}
          widgetProps={{
            buttonText: "Upgrade Subscription"
          }}>
          <span className='error'>* To make this category available you need to upgrade your subscription</span>
        </ManageSubscriptionDialog>
      </PageContent>
    </>
  );
}
