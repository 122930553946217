/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";
import { useMemo, useState } from "react";

import styles from "./styles.module.scss";

export default function (
  { action, className, children, ...rest }: CalloutProps
) {
  const classes = useMemo(() => {
    const cls = [styles.callout, "callout", className];
    return cls.join(" ").trim();
  }, [className])
 
  return (
    <Blueprint.Callout className={classes} {...rest}>
      <div className={styles.actions}>
        {action}
      </div>
      {children}
    </Blueprint.Callout>
  );
}

export type CalloutProps = Blueprint.CalloutProps & {
  action?: JSX.Element,
}