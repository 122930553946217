/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
  AsyncAction, CreateListOperation, DeleteListItemOperation,
  SyncController, UpdateListItemOperation,
  UpsertListItemOperation,
  failed, loading, success
} from "common/utils";
import { SyncProductsState } from '../product.store';
import { Product } from "../product.entities";


export function SyncProducts(ctrl: SyncController): AsyncAction {
  return async (dispatch, _, api) => {
    dispatch(SyncProductsState(loading()));
    try {
      const io = api.sync<Product>("/catalog/products", {
        onConnected: (socket) => socket.emit("sync"),
        onEvent: {
          data: (data) => {
            dispatch(SyncProductsState(success(new CreateListOperation(data))));
          },
          create: (item) => {
            dispatch(SyncProductsState(success(new UpsertListItemOperation(item, test))));
          },
          update: (item) => {
            dispatch(SyncProductsState(success(new UpdateListItemOperation(item, test))));
          },
          delete: (item) => {
            dispatch(SyncProductsState(success(new DeleteListItemOperation(item, test))));
          }
        },
        onError(error) {
          dispatch(SyncProductsState(failed(error)));
        },
      });
      ctrl.attach({
        onCancel: () => io.disconnect(),
      })
    } catch (error) {
      dispatch(SyncProductsState(failed(error)));
    }
  };
}

function test(a: Product, b: Product) {
  return a.id === b.id;
}