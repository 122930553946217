/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
  AsyncAction, CreateListOperation, ExtendListOperation,
  empty, failed, loading, success,
} from "common/utils";
import { LoadMoreOrdersHistoryState, LoadOrdersHistoryState } from "../order.store";


export function loadOrderHistory(limit: number, query?: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(LoadOrdersHistoryState(loading()));
    try {
      const { value } = await api.get(`/order-history`, {
        params: {
          $limit: limit,
          number: query == null ? undefined : `/${query}/`,
        },
      });
      dispatch(LoadOrdersHistoryState(success(new CreateListOperation(value))));
      dispatch(LoadMoreOrdersHistoryState(empty()));
    } catch (error) {
      dispatch(LoadOrdersHistoryState(failed(error)));
    }
  };
}

export function loadMoreOrderHistory(limit: number, from: number): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(LoadMoreOrdersHistoryState(loading()));
    try {
      const { value } = await api.get(`/order-history`, {
        params: {
          $limit: limit,
          $skip: from,
        }
      });
      dispatch(LoadMoreOrdersHistoryState(success(value)));
      dispatch(LoadOrdersHistoryState(success(new ExtendListOperation(value))));
    } catch (error) {
      dispatch(LoadMoreOrdersHistoryState(failed(error)));
    }
  };
}