/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { Business } from "business/business.entities";
import { AsyncState } from "common/utils/async-state";


export const BusinessStore = createSlice({
  name: "business",
  initialState: {
    save: {} as AsyncState<Business>,
    loadMyBusinesses: {} as AsyncState<Business[]>,
    setCurrentBusiness: {} as AsyncState<Business>,
    delete: {} as AsyncState<Business>,
  },
  reducers: {
    UpdateLoadMyBusinessesState: (
      state,
      action: { payload: AsyncState<Business[]> },
    ) => {
      state.loadMyBusinesses = action.payload;
    },
    SaveBusinessState: (
      state,
      action: { payload: AsyncState<Business> },
    ) => {
      state.save = action.payload;
    },
    UpdateDeleteBusinessState: (
      state,
      action: { payload: AsyncState<Business> },
    ) => {
      state.delete = action.payload;
    },
    UpdateSetCurrentBusinessState: (
      state,
      action: { payload: AsyncState<Business> },
    ) => {
      state.setCurrentBusiness = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, (state) => {
      state.save = {};
    });
  },
});

export const CurrentBusinessChanged = createAction("business/CurrentBusinessChanged");

export const {
  SaveBusinessState,
  UpdateLoadMyBusinessesState,
  UpdateSetCurrentBusinessState,
  UpdateDeleteBusinessState,
} = BusinessStore.actions;

export const { reducer: businessReducer, getInitialState } = BusinessStore;

export type BusinessState = ReturnType<typeof BusinessStore.getInitialState>
