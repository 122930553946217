/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsBoxSeam } from 'react-icons/bs';

import { Category } from "category/category.entities";
import Card, { CardProps } from "common/components/cards/Card";
import { useSyncProducts } from 'product/product.hooks';
import styles from "./styles.module.scss";


export default function CategoryCard({
  className, value, onClick, children, ...rest
}: CategoryCardProps) {
  const { t } = useTranslation();
  const classNames = useMemo(() =>
    [styles.categoryCard, className].join(" "),
    [className]);

  const { products } = useSyncProducts({
    filter: (product) => value.products && value.products.includes(product.id!) === true,
  });

  const productImages = useMemo(() => _.take(
    value.products.map(id => products.find(p => p.id === id)).filter(p => p?.image?.small),
    10).map((p, i) => <img
      key={i}
      src={p!.image!.small.url}
      data-available={value.available && p!.available} />),
    [products]);

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classNames}
      onClick={onClick}
      {...rest}>
      <h4>{value.name}</h4>
      {products.length === 0
        ? <p>{t("CategoriesGrid.noProductsYet")}</p>
        : <div className={styles.productImages}>
          {productImages.length === 0
            ? <BsBoxSeam />
            : productImages}
        </div>}
      {children}
    </Card>
  );
}

export type CategoryCardProps = CardProps & {
  value: Category,
};