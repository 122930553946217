/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { ListOperation } from "common/utils";
import { AsyncState } from "common/utils/async-state";
import { User } from "./user.entities";


export const UserState = createSlice({
  name: "user",
  initialState: {
    loadUser: {} as AsyncState<User>,
    loadUsers: {} as AsyncState<User[]>,
    loadMoreUsers: {} as AsyncState<User[]>,
    save: {} as AsyncState<User>,
  },
  reducers: {
    LoadUserState: (
      state,
      action: { payload: AsyncState<User> },
    ) => {
      state.loadUser = action.payload;
    },
    LoadUsersState: (
      state,
      action: { payload: AsyncState<ListOperation<User>> },
    ) => {
      state.loadUsers = {
        ...state.loadUsers,
        ...action.payload,
        value: action.payload.value?.apply(state.loadUsers.value) || state.loadUsers.value,
      }
    },
    LoadMoreUsersState: (
      state,
      action: { payload: AsyncState<User[]> },
    ) => {
      state.loadMoreUsers = action.payload;
    },
    SaveUserState: (
      state,
      action: { payload: AsyncState<User> },
    ) => {
      state.save = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState)
  },
});

export const {
  LoadUserState,
  LoadUsersState,
  LoadMoreUsersState,
  SaveUserState,
} = UserState.actions;

export const { reducer: userReducer, getInitialState } = UserState;

export type UserState = ReturnType<typeof UserState.getInitialState>
