/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Switch, SwitchProps } from "@blueprintjs/core";
import { OnChangeEventHandler } from "common/utils";

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function SwitchField({
  className, label, hidden, disabled, error,
  name, value, onChange, readOnly, actions, ...rest
}: SwitchFieldProps) {
  const { t } = useTranslation();
  const labelText = _.isArray(label) ? t(label[0], label[2], label[1]) : label;
  const classes = useMemo(() => {
    const classes = [styles.switchField, className];
    if (disabled)
      classes.push(styles.disabled)
    if (readOnly || onChange == null)
      classes.push(styles.readOnly)
    return classes.join(' ').trim();
  }, [className, readOnly, disabled, onChange]);

  return (
    <FormField
      className={classes}
      disabled={disabled}
      hidden={hidden}
      error={error}
      {...rest}>
      <Switch
        labelElement={
          <div className={styles.label}>
            {labelText}
            {actions}
          </div>
        }
        alignIndicator="right"
        disabled={disabled}
        checked={value}
        onChange={onChange == null || readOnly ? undefined : ({ target }) => {
          onChange!({ target: { name, value: (target as any).checked } });
        }}
      />
    </FormField>
  );
}

export type SwitchFieldProps = FormFieldProps & Omit<SwitchProps, "onChange" | "checked" | "label" | "value"> & {
  name: string,
  onChange?: OnChangeEventHandler<boolean>
  value?: boolean,
}