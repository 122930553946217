/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from "lodash";

import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaApple, FaRegEnvelope } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdPassword } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { AppState } from "app.store";
import { SendOtpAction } from "auth/actions/otp/send-otp.action";
import { LoginWithPincode } from "auth/actions/pincode/login-with-pincode.action";
import { Identity } from "auth/auth.entities";
import { Backout, Button, Card, CardContent, CardHeader, Column, Row } from "common/components";
import { ExPhoneField, Form, FormButton, TextInputField } from "common/components/form";
import FormTextInput from "common/components/form/TextInput";
import Toast from "common/components/toast";
import {
  formatExPhone,
  isFailed, isNotSuccessful, isSuccessful, useFormData, useInitial,
  useStateErrors,
} from "common/utils";

import { Callout } from "@blueprintjs/core";
import { LoginWithOtpAction } from "auth/actions/otp/login-with-otp.action";
import styles from './styles.module.scss';


export const IdentityContext = createContext<Identity | null>(null);

export default function AuthGuard({ role, layout }: AuthGuardProps) {
  const { t } = useTranslation();
  const initial = useInitial();
  const dispatch = useDispatch();

  const [showPincodeForm, setShowPincodeForm] = useState(false);
  const [showOtpSendForm, setShowOtpSendForm] = useState(false);
  const [showOtpLoginForm, setShowOtpLoginForm] = useState(false);

  // api state
  const loginState = useSelector((state: AppState) => state.auth.login);
  const sendOtpState = useSelector((state: AppState) => state.auth.otp.send);

  // form data
  const { formData, onChange, formErrors, setFormErrors } = useFormData<any>(null, {}, []);
  useStateErrors(loginState, setFormErrors);
  useStateErrors(sendOtpState, setFormErrors);

  useEffect(() => {
    if (!initial && isFailed(loginState)) {
      if (loginState.error!.code === 'invalid-pincode') {
        setFormErrors({
          pincode: ['Errors.invalidPincode', {
            count: loginState.error!.attemptsLeft
          }]
        });
      } else {
        Toast.showAsyncError(t, loginState.error!);
      }
    }
  }, [initial, loginState]);

  useEffect(() => {
    if (!initial && isSuccessful(sendOtpState)) {
      setShowOtpSendForm(false);
      setShowOtpLoginForm(true);
    }
  }, [initial, sendOtpState]);

  let child: JSX.Element;
  if (isNotSuccessful(loginState)) {
    child = (
      <div className="fullscreenGuard">
        <Card elevation={2}>
          <Row>
            <Column>
              <CardHeader
                title={
                  showOtpSendForm || showOtpLoginForm ? "One Time Password" :
                    showPincodeForm ? "Pincode" :
                      "Sign In / Sign Up"
                }
                left={showPincodeForm || showOtpSendForm || showOtpLoginForm ? (
                  <div style={{ marginLeft: -10 }}>
                    <Button
                      minimal
                      icon="arrow-left"
                      onClick={() => {
                        setShowPincodeForm(false);
                        setShowOtpSendForm(showOtpLoginForm);
                        setShowOtpLoginForm(false);
                      }} />
                  </div>
                ) : null}
                right={showPincodeForm ? (
                  <div style={{ width: 10 }} />
                ) : null}
              />
              <CardContent>
                <Form className={styles.form}>
                  <div
                    className={styles.formSection}
                    style={{ height: showOtpLoginForm || showOtpSendForm || showPincodeForm ? 0 : undefined }}>
                    <FormButton outlined
                      key="googleButton"
                      className={styles.continueButton}
                      icon={<FcGoogle size={18} />}
                      text="Continue with Google"
                      onClick={() => {
                        const redirectUrl = new URL('/on-login', window.location.href);
                        const googleAuthUrl = new URL('/auth/google/login', process.env.REACT_APP_API_URL!);
                        googleAuthUrl.searchParams.append('apiKey', process.env.REACT_APP_API_KEY!)
                        googleAuthUrl.searchParams.append('redirectTo', redirectUrl.toString());
                        window.open(googleAuthUrl.toString(), '_self');
                      }}
                    />
                    <FormButton outlined
                      key="appleButton"
                      className={styles.continueButton}
                      icon={<FaApple size={18} />}
                      text="Continue with Apple"
                      onClick={() => {
                        const redirectUrl = new URL('/on-login', window.location.href);
                        const googleAuthUrl = new URL('/auth/apple/login', process.env.REACT_APP_API_URL!);
                        googleAuthUrl.searchParams.append('apiKey', process.env.REACT_APP_API_KEY!)
                        googleAuthUrl.searchParams.append('redirectTo', redirectUrl.toString());
                        window.open(googleAuthUrl.toString(), '_self');
                      }}
                    />
                    <FormButton outlined
                      key="pincodeButton"
                      className={styles.continueButton}
                      icon={<MdPassword size={18} />}
                      text="Continue with Pincode"
                      onClick={() => setShowPincodeForm(true)}
                    />
                    <FormButton outlined
                      key="otpButton"
                      className={styles.continueButton}
                      icon={<FaRegEnvelope size={18} />}
                      text="One Time Password"
                      onClick={() => setShowOtpSendForm(true)}
                    />
                  </div>
                  <div className={styles.formSection} style={{ height: showPincodeForm ? undefined : 0 }}>
                    <TextInputField
                      autoFocus
                      name="username"
                      label="Username"
                      placeholder={"Email or Phone Number"}
                      autoCapitalize="none"
                      value={formData.username}
                      error={formErrors.username}
                      readOnly={loginState.isLoading}
                      onChange={onChange}
                    />
                    <FormTextInput
                      key="pincodeInput"
                      name="pincode"
                      label="Pin Code"
                      readOnly={loginState.isLoading}
                      value={formData.pincode}
                      error={formErrors.pincode}
                      type="password"
                      onChange={onChange}
                      onSubmit={() => {
                        if (!_.isEmpty(formData.username) && !_.isEmpty(formData.pincode))
                          dispatch(LoginWithPincode(formData.username, formData.pincode))
                      }}
                    />
                    <FormButton
                      outlined
                      text={"Continue"}
                      loading={loginState.isLoading}
                      disabled={_.isEmpty(formData.username) || _.isEmpty(formData.pincode)}
                      onClick={() => {
                        dispatch(LoginWithPincode(formData.username, formData.pincode))
                      }} />
                  </div>
                  <div className={styles.formSection} style={{ height: showOtpSendForm ? undefined : 0 }}>
                    <ExPhoneField
                      name="phone"
                      placeholder={"Phone Number"}
                      value={formData.phone}
                      error={formErrors?.phone}
                      onChange={(e) => {
                        onChange(e, {
                          target: {
                            name: 'email',
                            value: '',
                          }
                        });
                      }}
                    />
                    <span className={styles.otpOrLabel}>OR</span>
                    <TextInputField
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      error={formErrors?.email}
                      onChange={(e) => {
                        onChange(e, {
                          target: {
                            name: 'phone',
                            value: {
                              code: formData.phone?.code,
                              value: '',
                            }
                          }
                        });
                        if (formErrors?.phone) {
                          setFormErrors({ ...formErrors, phone: null });
                        }
                      }}
                    />
                    <div style={{height: 15}}/>
                    <FormButton
                      outlined
                      text={"Send Code"}
                      loading={sendOtpState.isLoading}
                      disabled={_.isEmpty(formData.phone?.number) && _.isEmpty(formData.email)}
                      onClick={() => {
                        if (_.isEmpty(formData.email)) {
                          dispatch(SendOtpAction(formData.phone, undefined))
                        } else {
                          dispatch(SendOtpAction(undefined, formData.email))
                        }
                      }} />
                  </div>
                  <div className={styles.formSection} style={{ height: showOtpLoginForm ? 210 : 0 }}>
                    <Callout intent="success" style={{ maxWidth: 260, marginBottom: 20 }}>
                      {`Enter the code that was sent to ${_.isEmpty(formData.email) ? formatExPhone(formData.phone) : formData.email}. This code will expire in 15 minutes.`}
                    </Callout>
                    <TextInputField
                      autoFocus
                      name="otp"
                      placeholder={"Verification Code"}
                      autoCapitalize="none"
                      value={formData.otp}
                      error={formErrors.otp}
                      readOnly={loginState.isLoading}
                      onChange={onChange}
                    />
                    <FormButton
                      outlined
                      text={"Continue"}
                      loading={loginState.isLoading}
                      disabled={_.isEmpty(formData.otp)}
                      onClick={() => {
                        dispatch(LoginWithOtpAction(sendOtpState.value!, formData.otp))
                      }} />
                  </div>
                </Form >
              </CardContent>
            </Column>
            <div className={styles.logobox}>
              <img
                src="/images/logo512.png"
                alt="logo"
              />
              <img
                src="/images/title1024.png"
                alt="title"
              />
            </div>
          </Row>
        </Card>
      </div >
    );
  } else if (role && loginState.value!.user.role !== role) {
    child = (
      <div className="fullscreenGuard">
        <Backout
          icon="lock"
          title={["AccessDenied"]}
          message={["YouDontHavePermission"]} />
      </div>
    );
  } else {
    child = (
      <IdentityContext.Provider value={loginState.value!}>
        <Outlet />
      </IdentityContext.Provider>
    );
  }

  return layout == null ? child : layout(child);
}

export interface AuthGuardProps {
  role?: string;
  layout?: (child: JSX.Element | null) => JSX.Element,
}