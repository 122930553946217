/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Icon, IconName, MaybeElement } from '@blueprintjs/core';
import React from "react";
import { useTranslation } from 'react-i18next';

import styles from "./styles.module.sass";


export default function CardHeader({
  className, icon, text, left, right, children, ...rest
}: CardHeaderProps) {
  // uses
  const { t } = useTranslation();
  // consts
  const classes = [styles.cardItem, className].join(" ");

  if (_.isString(icon))
    icon = <Icon icon={icon as any} size={15} />

  return (
    <div className={classes} {...rest}>
      <div className='icon'>{icon}</div>
      <div className='left'>{left}</div>
      <p>{_.isArray(text) ? t(text[0], text[2], text[1]) : text}</p>
      <div className='right'>{right}</div>
      {children}
    </div>
  );
}

export type CardHeaderProps = Omit<React.ComponentPropsWithRef<"div">, "title"> & {
  icon?: IconName | MaybeElement,
  left?: React.ReactNode,
  text?: string | [string, object?, string?],
  right?: React.ReactNode,
}
