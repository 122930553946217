/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";

import styles from "./styles.module.scss";


export default function ({
  className, hidden, children, ...rest
}: MenuDividerProps) {
  if (hidden)
    return null;

  const classes = [styles.menuDivider, className];
  return (
    <Blueprint.MenuDivider className={classes.join(" ")} {...rest}>
      {children}
    </Blueprint.MenuDivider>
  );
}

export type MenuDividerProps = Blueprint.MenuDividerProps & {
  hidden?: boolean;
}