/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AsyncAction, failed, loading, success } from "common/utils";
import { SendFeedbackState } from "../common.store";


export function sendFeedback(text: string): AsyncAction {
  return async (dispatch, getStore, api) => {
    dispatch(SendFeedbackState(loading()));
    try {
      const logsJson = localStorage.getItem('_logs') || "[]";
      const logs = JSON.parse(logsJson) as string[];
      const data = {
        'logs': logs.join('\n'),
        'platform': window.navigator.userAgent,
        'apiKey': process.env.REACT_APP_API_KEY,
        'appVersion': process.env.REACT_APP_VERSION,
        'text': text,
      };
      const identity = getStore().auth.login.value;
      if (identity != null) {
        data['accessToken'] = identity.accessToken;
        data['user'] = identity.user;
      }
      const { value } = await api.post('/feedback', { data })
      localStorage.removeItem("_logs");
      dispatch(SendFeedbackState(success(value)));
    } catch (error) {
      dispatch(SendFeedbackState(failed(error)));
    }
  };
}
