/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useState } from "react";
import { BiCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import CategoriesGrid from "category/containers/CategoriesGrid";


export default function CategoriesPage() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  return (
    <>
      <PageHeader
        icon={<BiCategory size={23} style={{ marginRight: 1, marginTop: -1 }} />}
        title={['Categories']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text="Create"
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <CategoriesGrid filterQuery={searchQuery} />
      </PageContent>
    </>
  );
}
