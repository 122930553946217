/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Branch } from "branch/branch.entities";
import { AsyncAction, failed, loading, success } from "common/utils";
import { DeleteBranchState } from "../branch.store";


export function DeleteBranch(id: string): AsyncAction {
  return async (dispatch, store, api) => {
    dispatch(DeleteBranchState(loading()));
    try {
      const { value } = await api.delete(`/business/branches/${id}`);
      dispatch(DeleteBranchState(success(value)));
    } catch (error) {
      dispatch(DeleteBranchState(failed(error)));
    }
  };
}
