/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Icon, IconProps } from "@blueprintjs/core";
import { Tooltip2, Tooltip2Props } from "@blueprintjs/popover2";
import { useMemo } from "react";

import styles from "./styles.module.scss";


export default function ({ className, iconProps, ...rest }: InfoTooltipProps) {
  const classNames = useMemo(() => {
    const classes = [styles.container, className];
    return classes.join(' ').trim();
  }, [className]);

  return (
    <Tooltip2 className={classNames} {...rest}>
      <Icon icon='help' size={12} {...iconProps} />
    </Tooltip2>
  );
}

export type InfoTooltipProps = Tooltip2Props & {
  iconProps?: Partial<IconProps>,
}