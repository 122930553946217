/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Catalog } from "catalog/catalog.entities";
import { useSyncCategories } from 'category/category.hooks';
import Card, { CardProps } from "common/components/cards/Card";
import styles from "./styles.module.scss";
import { Category } from 'category/category.entities';
import { useSyncProducts } from 'product/product.hooks';
import { Grid } from 'common/components';


export default function CatalogCard({
  className, value, onClick, children, ...rest
}: CatalogCardProps) {
  const { t } = useTranslation();
  const classNames = useMemo(() =>
    [styles.catalogCard, className].join(" "),
    [className]);

  const { categories } = useSyncCategories({
    filter: (category) => value.categories && value.categories.includes(category.id!) === true,
  });
  const { products } = useSyncProducts();

  const categoryImages = useMemo(() => _.take(
    value.categories.map(id => categories.find(p => p.id === id)).map(category => {
      if (!category) return [];
      return _.take(category.products.map(id =>
        products.find(p => p.id === id)).filter(p => p?.image?.small),
        4).map((p, i) => <img
          key={i}
          src={p!.image!.small.url}
          data-available={value.available && category.available && p?.available} />);
    }), 10),
    [products, categories, value]);

  return (
    <Card
      key={value.id}
      interactive={onClick != null}
      className={classNames}
      onClick={onClick}
      {...rest}>
      <h4>{value.name}</h4>
      {categoryImages.length === 0
        ? <p>{t("CategoriesGrid.noCategoriesYet")}</p>
        : <div className={styles.catalogImages}>
          {categoryImages.map((imgs, index) =>
            <Grid key={index} className={styles.categoryImages} md={2}>
              {imgs}
            </Grid>
          )}
        </div>}
      {children}
    </Card>
  );
}

export type CatalogCardProps = CardProps & {
  value: Catalog,
};