/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Alert, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  BackActivityIndicator, BackAsyncError, Backout, Button,
  Card, CardContent, CardHeader, Column,
  Grid, PageContent, PageHeader, PageNotFound, Toast,
} from 'common/components';
import {
  AsyncStateBuilder, isLoading, isSuccessful, useAppState,
  useFormData, useInitial, useStateErrors,
} from 'common/utils';
import { CreateProduct } from 'product/actions/create-product.action';
import { DeleteProduct } from 'product/actions/delete-product.action';
import { UpdateProduct } from 'product/actions/update-product.action';
import OptionGroupForm from 'product/components/OptionGroupForm';
import OptionGroupMenu from 'product/components/OptionGroupMenu';
import ProductForm from 'product/components/ProductForm';
import { Product, ProductOptionGroup } from 'product/product.entities';
import { useSyncProducts } from 'product/product.hooks';
import ManageSubscriptionDialog from 'subscription/components/ManageSubscriptionDialog';
import SubscriptionPlansDialog from 'subscription/components/SubscriptionPlansDialog';
import styles from "./styles.module.scss";

export default function ProductPage() {
  const { id } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();

  // store
  const { syncProductsState, product } = useSyncProducts({
    find: product => product.id === id,
  });
  const [saveState, dispatch] = useAppState((store) => store.product.save);
  const [deleteState] = useAppState((store) => store.product.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isSubscriptionPlansDialogVisible, setSubscriptionPlansDialogVisible] = useState(false);
  const [isManageSubscriptionDialogVisible, setManageSubscriptionDialogVisible] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<Product>(product, { available: true }, [product]);
  useStateErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (saveState.value?.status === 'no-subscription') {
          setTimeout(() => setSubscriptionPlansDialogVisible(true), 800);
          return
        }
        if (saveState.value?.status === 'upgrade-subscription') {
          setTimeout(() => setManageSubscriptionDialogVisible(true), 800);
          return
        }
        if (id === "new") {
          navigate("/products");
          Toast.showSuccess({ message: `A new product "${saveState.value!.entity.name}" has been added` });
        } else {
          Toast.showSuccess({ message: "The product has been updated" });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({ message: `The product "${deleteState.value!.name}" has been removed` });
        navigate('/products');
      } else if (deleteState.error) {
        Toast.showAsyncError(t, deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={product?.name || "New Product"}
        backButtonPath={state?.backpath || "/products"}>
        <Button
          text="Create"
          intent="success"
          onClick={() => dispatch(CreateProduct(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text="Save"
          intent="success"
          onClick={() => {
            const update = { ...changes };
            if (update.image?.frame) {
              update.image.frame = {
                ...formData.image!.frame,
                ...update.image.frame,
              }
            }
            dispatch(UpdateProduct(id!, update));
          }}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text="Delete"
                intent='danger'
                hidden={product == null}
                disabled={product == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder state={syncProductsState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!product && id !== "new")
              return <PageNotFound />
            return (
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardHeader title={["General"]} />
                  <CardContent>
                    <ProductForm
                      value={formData}
                      errors={formErrors}
                      readOnly={isLoading(saveState)}
                      onChange={onChange}
                      hiddenFields={["optionGroups"]}
                    />
                  </CardContent>
                </Card>
                <Column gap={20}>
                  {[0, null, undefined].includes(formData.optionGroups?.length)
                    ? <Card>
                      <CardHeader title={'Option Groups'} />
                      <CardContent>
                        <Backout
                          className={styles.noOptionGroupsBackout}
                          action={
                            <Button
                              text={["Add"]}
                              onClick={() => {
                                const groups: ProductOptionGroup[] = [{
                                  id: null as any,
                                  options: [{
                                    id: null as any,
                                    name: '',
                                    price: 0,
                                  }],
                                  minSelected: 0,
                                  maxSelected: 1
                                }];
                                onChange({
                                  target: {
                                    name: 'optionGroups',
                                    value: groups,
                                  },
                                })
                              }} />
                          } />
                      </CardContent>
                    </Card>
                    : formData.optionGroups!.map((optionGroup, index) => (
                      <Card key={`optionGroups_${index}`}>
                        <CardHeader
                          title={`Option Group ${index + 1}`}
                          right={
                            <Popover2
                              placement="bottom-end"
                              content={
                                <OptionGroupMenu
                                  product={formData}
                                  optionGroupIndex={index}
                                  onChange={onChange}
                                />
                              }>
                              <Button minimal rightIcon="more" />
                            </Popover2>
                          }
                        />
                        <CardContent>
                          <OptionGroupForm
                            name={`optionGroups.${index}`}
                            value={optionGroup}
                            errors={_.get(formErrors?.optionGroups, index)}
                            onChange={onChange}
                            readOnly={isLoading(saveState)}
                          />
                        </CardContent>
                      </Card>
                    ))}
                </Column>
              </Grid>
            );
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText='Delete'
          cancelButtonText='Nope'
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteProduct(id!))}>
          <p>Delete this product?</p>
        </Alert>
        <SubscriptionPlansDialog
          title="To make this product available you need a Subscription"
          isOpen={isSubscriptionPlansDialogVisible}
          onClose={() => setSubscriptionPlansDialogVisible(false)} />
        <ManageSubscriptionDialog
          title="My Subscription"
          isOpen={isManageSubscriptionDialogVisible}
          onClose={() => setManageSubscriptionDialogVisible(false)}
          widgetProps={{
            buttonText: "Upgrade Subscription"
          }}>
          <span className='error'>* To make this product available you need to upgrade your subscription</span>
        </ManageSubscriptionDialog>
      </PageContent>
    </>
  );
}
