/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Dialog, DialogBody, DialogFooter, Menu, MenuProps, TextArea } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { AiOutlineBank } from "react-icons/ai";
import { BiCategory, BiMedal, BiStore } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { IoIosApps } from "react-icons/io";
import { LuPackageOpen, LuPackageSearch } from "react-icons/lu";
import { MdOutlineBugReport, MdOutlineBusinessCenter, MdOutlineCategory } from "react-icons/md";
import { PiMagicWand } from "react-icons/pi";
import { RiBookLine } from "react-icons/ri";
import { TbChecklist } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";

import { useHasEmployeeRole, useHasUserRole } from "auth/auth.hooks";
import { sendFeedback } from "common/actions/send-feedback.action";
import { SendFeedbackState } from "common/common.store";
import { MenuDivider, MenuItem, Tag, Toast } from "common/components";
import { isSuccessful, useAppState, useStateErrors } from "common/utils";
import { useSyncOrders } from "order/order.hooks";
import styles from "./styles.module.sass";


export default function ({
  className, onOpenChecklist, ...rest
}: AppSidebarProps) {
  // uses
  const navigate = useNavigate();
  const location = useLocation();
  // consts
  const classes = [styles.appsidebar, className].join(" ");

  // hooks
  const { orders } = useSyncOrders({ auto: false });
  const hasUserRole = useHasUserRole();
  const hasEmployeeRole = useHasEmployeeRole();

  // feedback
  const [isFeedbackDialogShown, showFeedbackDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [sendFeedbackState, dispatch] = useAppState((state) => state.common.sendFeedback);
  useStateErrors(sendFeedbackState);
  useEffect(() => {
    if (isSuccessful(sendFeedbackState)) {
      Toast.showSuccess({
        message: "Feedback Sent! Thank you for reaching out to us!"
      });
      dispatch(SendFeedbackState({}));
      setFeedbackText('');
      showFeedbackDialog(false);
    }
  }, [sendFeedbackState])

  return (
    <Menu className={classes} {...rest}>
      <MenuDivider title="Orders" />
      <MenuItem
        icon={<LuPackageOpen size={19} style={{ paddingRight: 1 }} />}
        text="Manage Orders"
        selected={location.pathname.startsWith('/orders')}
        onClick={() => navigate('/orders')}
        labelElement={
          <Tag minimal
            hidden={orders.length === 0}
            intent="success">
            {orders.length}
          </Tag>
        }
      />
      <MenuItem
        icon={<LuPackageSearch size={19} style={{ paddingRight: 1 }} />}
        text="Order History"
        selected={location.pathname.startsWith('/order-history')}
        onClick={() => navigate('/order-history')}
      />
      <MenuItem
        icon="series-search"
        text="Sales Report"
        selected={location.pathname.startsWith('/reports/sales')}
        hidden={!hasEmployeeRole('admin', 'manager')}
        onClick={() => navigate('/reports/sales')}
      />

      <MenuDivider title="Menu" />
      <MenuItem
        icon={<PiMagicWand size={18} style={{ marginLeft: 1, marginRight: 3 }} />}
        onClick={() => navigate('/import-menu')}
        selected={location.pathname.startsWith('/import-menu')}
        text={"AI Import"} />
      <MenuItem
        icon={<BsBoxSeam size={16} style={{ marginLeft: 1, marginRight: 3 }} />}
        onClick={() => navigate('/products')}
        selected={location.pathname.startsWith('/products')}
        text={"Products"} />
      <MenuItem
        icon={<BiCategory size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/categories')}
        selected={location.pathname.startsWith('/categories')}
        text="Categories" />
      <MenuItem
        icon={<RiBookLine size={18} style={{ marginRight: 3 }} />}
        onClick={() => navigate('/catalogs')}
        selected={location.pathname.startsWith('/catalogs')}
        text={"Catalogs"} />
      <MenuDivider
        title="Organization"
        hidden={!hasEmployeeRole('admin', 'manager')}
      />
      <MenuItem
        icon={<AiOutlineBank size={18} style={{ marginBottom: 2, marginLeft: 1, marginRight: 1 }} />}
        onClick={() => navigate('/merchants')}
        selected={location.pathname.startsWith('/merchants')}
        hidden={!hasEmployeeRole('admin')}
        text="Merchant Accounts" />
      <MenuItem
        icon={<BiMedal size={21} style={{ marginLeft: -1 }} />}
        onClick={() => navigate('/subscription')}
        selected={location.pathname.startsWith('/subscription')}
        hidden={!hasEmployeeRole('admin')}
        text="Subscription" />
      <MenuItem
        icon={<BiStore size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/branches')}
        selected={location.pathname.startsWith('/branches')}
        hidden={!hasEmployeeRole('admin', 'manager')}
        text="Branches" />
      <MenuItem
        icon={<FiUsers size={17} style={{ marginLeft: 2, marginRight: 1 }} />}
        onClick={() => navigate('/employees')}
        selected={location.pathname.startsWith('/employees')}
        hidden={!hasEmployeeRole('admin')}
        text="Staff" />
      {/* <MenuItem
        icon={<MdOutlineTableBar size={18} style={{ marginLeft: 1, marginRight: 2 }} />}
        onClick={() => navigate('/tables')}
        hidden={identity?.business?.category !== 'restaurant'}
        selected={location.pathname.startsWith('/tables')}
        text="Tables" /> */}
      <MenuItem
        icon={<MdOutlineBusinessCenter size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/business')}
        selected={location.pathname.startsWith('/business')}
        hidden={!hasEmployeeRole('admin')}
        text="Business Profile" />

      <MenuDivider title="Help" />
      <MenuItem
        icon={<TbChecklist size={18} />}
        text={["Onboarding Instructions"]}
        hidden={onOpenChecklist == null || !hasEmployeeRole()}
        onClick={(e) => {
          onOpenChecklist!();
        }}
      />
      <MenuItem
        icon={<MdOutlineCategory size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/digital-marketing-assets')}
        selected={location.pathname.startsWith('/digital-marketing-assets')}
        text="Digital Marketing Assets" />
      <MenuItem
        icon={<IoIosApps size={19} style={{ paddingRight: 1 }} />}
        onClick={() => navigate('/apps')}
        selected={location.pathname.startsWith('/apps')}
        text="Applications" />
      <MenuItem
        icon={<MdOutlineBugReport size={19} style={{ paddingRight: 1 }} />}
        onClick={() => showFeedbackDialog(!isFeedbackDialogShown)}
        text="Send Feedback" />

      {/* Administration*/}
      <MenuDivider
        title="Super Admin"
        hidden={!hasUserRole("admin")}
      />
      <MenuItem
        icon={<FiUsers size={17} style={{ marginLeft: 2, marginRight: 1 }} />}
        onClick={() => navigate('/admin/users')}
        text="Users"
        hidden={!hasUserRole("admin")}
        selected={location.pathname.startsWith('/admin/users')}
      />
      {/* <MenuDivider title="Settings" />
      <MenuItem
        icon={<LuLanguages size={17} style={{ marginLeft: 7, marginRight: 1 }} />}
        text="Language" >
        <MenuItem icon="blank" text="Russian" />
        <MenuItem icon="tick" text="English" />
      </MenuItem> */}
      <Dialog
        icon={<MdOutlineBugReport size={18} style={{ marginRight: 4 }} />}
        title={"Send Feedback"}
        isCloseButtonShown={true}
        isOpen={isFeedbackDialogShown}
        onClose={() => showFeedbackDialog(false)}>
        <DialogBody>
          <TextArea
            style={{ width: '100%', height: 80 }}
            placeholder="Type your feedback here"
            value={feedbackText}
            onChange={e => setFeedbackText(e.target.value)}
          />
        </DialogBody>
        <DialogFooter
          actions={
            <Button
              text="Send"
              intent="primary"
              loading={sendFeedbackState.isLoading}
              onClick={() => {
                dispatch(sendFeedback(feedbackText));
              }}
            />
          } />
      </Dialog>
    </Menu>
  );
}

export type AppSidebarProps = Omit<MenuProps, "children"> & {
  onOpenChecklist?: () => void,
}