/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { BsBoxSeam } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import {
  BackAsyncError, Backout, Button,
  Grid, GridContentLoader
} from "common/components";
import { AsyncStateBuilder } from "common/utils";
import ProductCard from 'product/components/ProductCard';
import { Product } from '../../product.entities';
import { useSyncProducts } from "../../product.hooks";


export default function ProductsGrid({ filterQuery }: ProductsGridProps) {
  const navigate = useNavigate();
  const { syncProductsState, products } = useSyncProducts({
    filter: (product) =>
      _.isEmpty(filterQuery) || _.values(product).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <AsyncStateBuilder<Product[]>
      state={syncProductsState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onError={(error) => <BackAsyncError error={error} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={<BsBoxSeam style={{ marginTop: -3, marginLeft: -2 }} />}
            title="No Products Yet">
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["Create"]}
                onClick={() => navigate("/products/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {products.map(product =>
            <ProductCard
              key={product.id}
              value={product}
              onClick={() => navigate(product.id!)}
            />
          )}
        </Grid>
      }
    />
  );
}

export type ProductsGridProps = {
  filterQuery?: string | null;
}
