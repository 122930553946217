/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { AiOutlineBank } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { BackAsyncError, Backout, Button, Grid, GridContentLoader } from "common/components";
import { AsyncStateBuilder } from "common/utils";
import MerchantAccountCard from 'merchant/common/components/MerchantAccountCard';
import { MerchantAccount } from '../../../merchant.entities';
import { useSyncMerchantAccounts } from "../../../merchant.hooks";


export default function MerchantAccountsGrid({ filterQuery }: MerchantAccountsGridProps) {
  const navigate = useNavigate();
  const { syncMerchantAccountsState, merchants } = useSyncMerchantAccounts({
    filter: (merchant) =>
      _.isEmpty(filterQuery) || _.values(merchants).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <AsyncStateBuilder<MerchantAccount[]>
      state={syncMerchantAccountsState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onError={(error) => <BackAsyncError error={error} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={<AiOutlineBank style={{ marginTop: -3, marginLeft: -2 }} />}
            title="No Merchant Accounts Yet">
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["Create"]}
                onClick={() => navigate("/merchants/stripe/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {merchants.map(merchant =>
            <MerchantAccountCard
              key={merchant.id}
              value={merchant}
              onClick={() => navigate(merchant.type + '/' + merchant.id!)}
            />
          )}
        </Grid>
      }
    />
  );
}

export type MerchantAccountsGridProps = {
  filterQuery?: string | null;
}
