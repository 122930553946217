/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as Blueprint from "@blueprintjs/core";

import styles from "./styles.module.scss";


export default function ({
  className, hidden, children, ...rest
}: MenuItemProps) {
  if (hidden)
    return null;

  const classes = [styles.menuItem, className];
  return (
    <Blueprint.MenuItem className={classes.join(" ")} {...rest}>
      {children}
    </Blueprint.MenuItem>
  );
}

export type MenuItemProps = Blueprint.MenuItemProps & {
  hidden?: boolean;
}