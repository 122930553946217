/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import moment from 'moment';

import { DateRangeInput } from "@blueprintjs/datetime";
import { useEffect, useState } from "react";

import {
  BackActivityIndicator, BackAsyncError, Backout,
  Card, CardContent, CardHeader, CardItem, Column,
  Grid, InfoTooltip, PageContent, PageHeader,
} from "common/components";
import { AsyncStateBuilder, isEmpty, useAppState } from "common/utils";
import { loadBasicSalesReportAction } from 'report/actions/load-basic-sales-report.action';

import styles from "./styles.module.scss";


export default function () {
  const [loadBasicSalesReportState, dispatch] = useAppState((state) => state.report.loadBasicSalesReport);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
    [moment().add(-1, 'month').toDate(), moment().toDate()]);

  useEffect(() => {
    if (isEmpty(loadBasicSalesReportState) && dateRange[0] != null) {
      dispatch(loadBasicSalesReportAction(dateRange[0]!, dateRange[1]!));
    }
  }, [loadBasicSalesReportState, dateRange])

  return (
    <>
      <PageHeader title="Sales Report">
        <DateRangeInput
          className={styles.dateRangeInput}
          formatDate={(date) => moment(date).format('MMM D YYYY')}
          parseDate={(str) => moment(str).toDate()}
          onChange={(range) => {
            setDateRange(range);
            dispatch(loadBasicSalesReportAction(range[0]!, range[1]!));
          }}
          value={dateRange}
        />
      </PageHeader>
      <PageContent>
        <AsyncStateBuilder
          state={loadBasicSalesReportState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueNull={() => {
            return <Backout
              icon="series-search"
              message={"No data found for the selected period"} />
          }}
          onValue={(report) => {
            return (
              <>
                <h3>Report from {moment(dateRange[0]).format('MMMM D YYYY')} to
                  {moment(dateRange[1]).format(' MMMM D YYYY')}</h3>
                <Grid md={2} xs={1} gap={20}>
                  <Card style={{ minHeight: 400 }}>
                    <CardHeader title={`Sales Summary (${report.currency})`} />
                    <CardContent>
                      <Column className={styles.salesReportBody}>
                        <CardItem
                          text={"• Number of Orders"}
                          right={report.orders.length}
                        />
                        <CardItem
                          left={
                            <span>
                              • Net Amount (Online Payments) &nbsp;
                              <InfoTooltip
                                content={'This amount is paid to your nominated bank account'}
                                position="top-left"
                                iconProps={{ style: { marginBottom: 2 } }} />
                            </span>
                          }
                          right={report.stripeAmount.toFixed(2)}
                        />
                        <CardItem
                          text={"• Net Amount (Upon-Arrival Payments)"}
                          right={report.cashAmount.toFixed(2)}
                        />
                        <CardItem
                          text={"• Net Amount (Total)"}
                          right={report.totalAmount.toFixed(2)}
                        />
                        <h3>List of Items Sold</h3>
                        {report.topProducts.map(product => (
                          <>
                            <CardItem
                              key={'product_' + product.id}
                              text={product.name}
                              right={`${product.totalQuantity} / ${product.totalAmount.toFixed(2)}`}
                            />
                            {product.topOptions?.map(option => (
                              <CardItem
                                key={'option_' + option.id}
                                text={"- " + option.name}
                                right={`${option.totalQuantity} / ${option.totalAmount.toFixed(2)}`}
                              />
                            ))}
                          </>
                        ))}
                        <h3>Top Customers by Number of Orders</h3>
                        {report.topCustomersByNumberOfOrders.map(customer => (
                          <>
                            <CardItem
                              key={'customerByOrders_' + customer.id}
                              text={customer.name}
                              right={`${customer.numberOfOrders} / ${customer.totalAmount.toFixed(2)}`}
                            />
                          </>
                        ))}
                        <h3>Top Customers by Amount Spent</h3>
                        {report.topCustomersByTotalAmount.map(customer => (
                          <CardItem
                            key={'customerByAmount_' + customer.id}
                            text={customer.name}
                            right={`${customer.numberOfOrders} / ${customer.totalAmount.toFixed(2)}`}
                          />
                        ))}
                      </Column>
                    </CardContent>
                  </Card>
                  <Card style={{ minHeight: 400 }}>
                    <CardHeader title={`Detailed Sales (${report.currency})`} />
                    <CardContent>
                      <Column className={styles.salesReportBody}>
                        <table className={styles.detailedReportTable}>
                          <thead>
                            <tr>
                              <td>Date/Time</td>
                              <td>Price</td>
                              <td>Net Amount</td>
                              <td>Order ID</td>
                            </tr>
                          </thead>
                          <tbody>
                            {report.orders.map((item) =>
                              <tr key={item.number}>
                                <td>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td>{item.totalPrice.toFixed(2)}</td>
                                <td>{item.netAmount.toFixed(2)}</td>
                                <td><a href={`/order-history/${item.type}/${item.id}`} target='_blank'>{item.number}</a></td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Column>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          }} />
      </PageContent>
    </>
  );
}
