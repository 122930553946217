/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useCallback, useEffect } from "react";

import { isEmpty, useAppState } from "common/utils";
import { GetCurrentSubscription } from "subscription/actions/get-current-subscription.action";


export function useGetSubscription(opts: { auto?: boolean } = {}, deps: any[] = []) {
  const [getCurrentSubscriptionState, dispatch] =
    useAppState((store) => store.subscription.getCurrentSubscription);

  const getCurrentSubscription = useCallback(() => {
    dispatch(GetCurrentSubscription())
  }, []);

  useEffect(() => {
    if (opts.auto !== false && isEmpty(getCurrentSubscriptionState))
      getCurrentSubscription();
  }, [opts.auto, getCurrentSubscriptionState]);

  return { getCurrentSubscriptionState, getCurrentSubscription };
}