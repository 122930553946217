/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { Form, SwitchField, TextAreaField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { Catalog } from "catalog/catalog.entities";
import { useFieldName, useIsFieldHidden } from "common/utils";
import styles from "./styles.module.sass";


export default function CatalogForm<T extends Catalog>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: CatalogFormProps<T>) {
  const classes = useMemo(() => [styles.catalogForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("available")}
        label={["Available"]}
        value={value?.available}
        error={errors?.available}
        readOnly={readOnly}
        hidden={isHidden("available")}
        onChange={onChange}
      />
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextAreaField
        name={fieldName("description")}
        label="Description"
        value={value?.description}
        error={errors?.description}
        readOnly={readOnly}
        hidden={isHidden("description")}
        onChange={onChange} />
      {children}
    </Form>
  );
}

export type CatalogFormProps<T extends Catalog> = EntityFormProps<T> & {
};