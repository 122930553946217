/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { createSlice } from "@reduxjs/toolkit";

import { Logout } from "auth/auth.store";
import { CurrentBusinessChanged } from "business/business.store";
import { AsyncState } from "common/utils/async-state";
import { BasicSalesReport } from "report/report.entities";


export const ReportStore = createSlice({
  name: "report",
  initialState: {
    loadBasicSalesReport: {} as AsyncState<BasicSalesReport>,
  },
  reducers: {
    UpdateLoadBasicSalesReportState: (
      state,
      action: { payload: AsyncState<BasicSalesReport> },
    ) => {
      state.loadBasicSalesReport = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(Logout, getInitialState);
    builder.addCase(CurrentBusinessChanged, getInitialState);
  },
});

export const {
  UpdateLoadBasicSalesReportState,
} = ReportStore.actions;

export const { reducer: reportReducer, getInitialState } = ReportStore;

export type ReportState = ReturnType<typeof ReportStore.getInitialState>
