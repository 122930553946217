/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useMemo, useState } from "react";
import { FaQrcode } from "react-icons/fa";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png";
import timezones from 'timezones-list';

import { Dialog, Icon } from '@blueprintjs/core';
import { Branch } from "branch/branch.entities";
import BranchCatalogForm from "branch/components/BranchCatalogForm";
import {
  Button, ClipboardButton, DropdownField, ExPhoneField,
  Form, FormField, InfoTooltip, SwitchField,
  TextAreaField, TextInputField,
} from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { ExImageField } from "media/components";

import styles from "./styles.module.scss";

export default function BranchForm<T extends Branch>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: BranchFormProps<T>) {
  const classes = useMemo(() => [styles.branchForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const [qrCodeToDisplay, setQrCodeToDisplay] = useState('');

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("online")}
        label={["Online"]}
        value={value?.online}
        error={errors?.online}
        readOnly={readOnly}
        hidden={isHidden("online")}
        onChange={onChange}
      />
      <ExImageField
        name={fieldName("image")}
        label={["Image"]}
        value={value?.image}
        error={errors?.image}
        readOnly={readOnly}
        hidden={isHidden("image")}
        onChange={onChange}
        frameProps={{
          height: 200,
          width: 300,
        }} />
      <TextInputField
        name={fieldName("name")}
        label={["Name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextAreaField
        name={fieldName("description")}
        label="Description"
        value={value?.description}
        error={errors?.description}
        readOnly={readOnly}
        hidden={isHidden("description")}
        onChange={onChange} />
      <ExPhoneField
        name={fieldName("phone")}
        label={["Phone"]}
        value={value?.phone}
        error={errors?.phone}
        readOnly={readOnly}
        hidden={isHidden("phone")}
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Displayed to customers for order inquiries'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      <ExPhoneField
        name={fieldName("whatsapp")}
        label={["WhatsApp"]}
        value={value?.whatsapp}
        error={errors?.whatsapp}
        readOnly={readOnly}
        hidden={isHidden("whatsapp")}
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Specify your business WhatsApp account number for customers to communicate order inquiries'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      <TextInputField
        name={fieldName("email")}
        label={["Email"]}
        autoCapitalize='none'
        value={value?.email}
        error={errors?.email}
        readOnly={readOnly}
        hidden={isHidden("email")}
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Used for order notifications and enquiries only if  WhatsApp or Push Notifications are not available'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      <TextInputField
        name={fieldName("slug")}
        label={["Slug"]}
        leftElement={<span className={styles.slugPrefix}>{process.env.REACT_APP_SLUG_PREFIX}</span>}
        autoCapitalize='none'
        value={value?.slug}
        error={errors?.slug}
        readOnly={readOnly}
        hidden={isHidden("slug")}
        onChange={onChange}
        rightElement={
          <>
            <Button minimal
              icon={<FaQrcode />}
              hidden={_.isEmpty(value?.slug)}
              onClick={(e) => {
                e.stopPropagation();
                setQrCodeToDisplay(process.env.REACT_APP_SLUG_PREFIX! + value?.slug);
              }}
            />
            <ClipboardButton
              hidden={_.isEmpty(value?.slug)}
              textToCopy={process.env.REACT_APP_SLUG_PREFIX! + value?.slug}
            />
          </>
        }
      />
      <TextInputField
        name={fieldName("keywords")}
        label="Tags"
        autoCapitalize='none'
        value={value?.keywords}
        error={errors?.keywords}
        readOnly={readOnly}
        hidden={isHidden("keywords")}
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Specify comma separated list of tags that are searchable by customers'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        } />
      <FormField
        label={["Catalogs"]}
        hidden={isHidden("catalogs")} >
        {(value?.catalogs || []).map((catalog, i) => (
          <BranchCatalogForm
            key={`catalog_${i}`}
            name={fieldName(`catalogs.${i}`)}
            value={catalog}
            errors={errors?.catalogs}
            readOnly={readOnly}
            // hidden={isHidden(`optionGroups.${i}`)}
            onChange={onChange}
          />
        ))}
      </FormField>
      <DropdownField
        nullable={value?.timezone == null}
        name="timezone"
        label={["Time Zone"]}
        value={value?.timezone}
        error={errors?.timezone}
        hidden={isHidden("timezone")}
        options={timezones.map(tz => ({
          id: tz.tzCode!,
          value: tz.tzCode,
          name: tz.label,
        }))}
        onChange={(event) => {
          onChange({
            target: {
              name: fieldName("timezone"),
              value: event.target.value,
            }
          })
        }}
      />
      <SwitchField
        name={fieldName("deliveryOptions.inStorePickup")}
        label={["In-Store Pickup"]}
        value={value?.deliveryOptions?.inStorePickup}
        error={errors?.deliveryOptions?.inStorePickup}
        readOnly={readOnly}
        hidden={isHidden("deliveryOptions.inStorePickup")}
        disabled={
          value?.deliveryOptions?.curbsidePickup !== true &&
          value?.deliveryOptions?.dineIn !== true
        }
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Customers place orders online and pick them up inside your store'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      <SwitchField
        name={fieldName("deliveryOptions.curbsidePickup")}
        label={["Curbside Pickup"]}
        value={value?.deliveryOptions?.curbsidePickup}
        error={errors?.deliveryOptions?.curbsidePickup}
        readOnly={readOnly}
        hidden={isHidden("deliveryOptions.curbsidePickup")}
        disabled={
          value?.deliveryOptions?.inStorePickup !== true &&
          value?.deliveryOptions?.dineIn !== true
        }
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Allow your customers to place orders online and have your staff deliver them directly to their cars upon arrival'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      <SwitchField
        name={fieldName("deliveryOptions.dineIn")}
        label={["Table Ordering"]}
        value={value?.deliveryOptions?.dineIn}
        error={errors?.deliveryOptions?.dineIn}
        readOnly={readOnly}
        hidden={isHidden("deliveryOptions.dineIn")}
        disabled={
          value?.deliveryOptions?.inStorePickup !== true &&
          value?.deliveryOptions?.curbsidePickup !== true
        }
        onChange={onChange}
        actions={
          <InfoTooltip
            content={'Allows you to generate unique QR codes for each table, making it easy for customers to place orders directly from their table using their smartphones'}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        }
      />
      {children}
      <Dialog
        className={styles.qrDialog}
        style={{ width: 350, padding: 20 }}
        isOpen={qrCodeToDisplay.length > 0}
        onClose={() => setQrCodeToDisplay('')} >
        <QRCode
          id='branch_qr_code'
          size={350}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={qrCodeToDisplay}
          viewBox={`0 0 350 350`}
        />
        <Icon
          className={styles.saveQrButton}
          size={40}
          icon="download"
          onClick={() => {
            setQrCodeToDisplay('');
            saveSvgAsPng(
              document.getElementById('branch_qr_code'),
              `${value!.name} QR.png`,
              { scale: 10 },
            );
          }}
        />
      </Dialog>
    </Form>
  );
}

export type BranchFormProps<T extends Branch> = EntityFormProps<T> & {
};