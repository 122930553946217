/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { FaAppStore, FaGooglePlay } from "react-icons/fa";
import { IoIosApps } from "react-icons/io";
import { TbWorldWww } from "react-icons/tb";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png";

import {
  Button, Card, CardContent, CardHeader,
  Grid, PageContent, PageHeader
} from "common/components";
import styles from './styles.module.scss';

export default function ApplicationsPage() {
  return (
    <>
      <PageHeader
        icon={<IoIosApps size={22} style={{ marginRight: 2, marginTop: -1.5 }} />}
        title={['Applications']} >
      </PageHeader>
      <PageContent>
        <Grid md={2} xs={1} gap={20}>
          <Card>
            <CardHeader title={["DishDash Business for Staff"]} />
            <CardContent>
              <b>Manage Your Orders with Ease</b>
              <br />
              <br />
              <p>The DishDash Business app allows businesses to efficiently manage curbside pickup orders. Alternatively, businesses can use the web interface for the same purpose.</p>
              <div className={styles.appItem}>
                <FaAppStore size={40} />
                <b>Download DishDash Business on the App Store</b>
                <p>DishDash Business for iPhone and iPad users</p>
                <a className="bp4-button"
                  href="https://apps.apple.com/us/app/geome-drive-thru/id1489848824"
                  target="_blank">Download for iOS</a>
              </div>
              <div className={styles.appItem}>
                <FaGooglePlay size={40} />
                <b>Get DishDash Business on Google Play</b>
                <p>DishDash Business for Android devices</p>
                <a className="bp4-button"
                  href="https://play.google.com/store/apps/details?id=io.geome.drivethru"
                  target="_blank">Download for Android</a>
              </div>
              <div className={styles.appItem}>
                <TbWorldWww size={40} />
                <b>Access DishDash Business with a browser</b>
                <p>Manage your orders from any browser</p>
                <a className="bp4-button"
                  href="https://staff.dishdash.io"
                  target="_blank">Use Browser</a>
              </div>
              <br />
              <div className={styles.appItem}>
                <QRCode
                  id='fba_qr_code'
                  size={100}
                  style={{ height: "100px", width: "100px" }}
                  value={"https://dl.dishdash.io/dt/naxz"}
                  viewBox={`0 0 350 350`}
                />
                <b>Scan QR Code to Download</b>
                <p>Scan the QR code with your mobile device to download the app directly from the respective app store</p>
                <Button
                  text="Save QR Code"
                  onClick={() => {
                    saveSvgAsPng(
                      document.getElementById('fba_qr_code'),
                      'DishDash Business QR.png',
                      { scale: 10 },
                    );
                  }}
                />
              </div>
              <br />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={["DishDash for Consumers"]} />
            <CardContent>
              <b>Place Orders and Pick Them Up Curbside</b>
              <br />
              <br />
              <p>The DishDash app allows consumers to place curbside pickup orders quickly and conveniently. Consumers can also use the web application.</p>
              <div className={styles.appItem}>
                <FaAppStore size={40} />
                <b>Download DishDash on the App Store</b>
                <p>DishDash for iPhone and iPad users</p>
                <a className="bp4-button"
                  href="https://apps.apple.com/us/app/geome/id1489847032"
                  target="_blank">Download for iOS</a>
              </div>
              <div className={styles.appItem}>
                <FaGooglePlay size={40} />
                <b>Get DishDash on Google Play</b>
                <p>DishDash for Android devices</p>
                <a className="bp4-button"
                  href="https://play.google.com/store/apps/details?id=io.geome.user"
                  target="_blank">Download for Android</a>
              </div>
              <div className={styles.appItem}>
                <TbWorldWww size={40} />
                <b>Access DishDash with a browser</b>
                <p>Place your orders from any browser</p>
                <a className="bp4-button"
                  href="https://web.dishdash.io"
                  target="_blank">Use Browser</a>
              </div>
              <br />
              <div className={styles.appItem}>
                <QRCode
                  id='fua_qr_code'
                  size={100}
                  style={{ height: "100px", width: "100px" }}
                  value={"https://dl.dishdash.io/u/85Up"}
                  viewBox={`0 0 350 350`}
                />
                <b>Scan QR Code to Download</b>
                <p>Scan the QR code with your mobile device to download the app directly from the respective app store</p>
                <Button
                  text="Save QR Code"
                  onClick={() => {
                    saveSvgAsPng(
                      document.getElementById('fua_qr_code'),
                      'DishDash QR.png',
                      { scale: 10 },
                    );
                  }}
                />
              </div>
              <br />
              <br />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
